var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var lha,mha,nha,oha,pha,qha,rha;$CLJS.TF=new $CLJS.M(null,"second","second",-444702010);$CLJS.UF=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);$CLJS.VF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.WF=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.XF=new $CLJS.M(null,"year-of-era","year-of-era",682445876);
lha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);mha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.YF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);
$CLJS.ZF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);$CLJS.$F=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);nha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);
oha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);pha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);qha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);
$CLJS.aG=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.bG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);$CLJS.cG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
rha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var dG=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Ak,$CLJS.Gk,$CLJS.dj,$CLJS.ci,$CLJS.Jk,$CLJS.zk,$CLJS.Wh,$CLJS.XF],null),eG=$CLJS.Wg(dG),sha,tha,uha;$CLJS.Y(pha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid date extraction unit"],null)],null),eG));var fG=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Lk,$CLJS.Ri,$CLJS.Xi,$CLJS.bi,$CLJS.Wh],null),gG=$CLJS.Wg(fG);
$CLJS.Y(oha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid date truncation unit"],null)],null),gG));$CLJS.hG=$CLJS.Wf.j($CLJS.xf,$CLJS.tk.o(),$CLJS.bf.g(fG,dG));sha=$CLJS.Wg($CLJS.hG);$CLJS.Y($CLJS.$F,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid date bucketing unit"],null)],null),sha));var iG=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UF,$CLJS.Dk,$CLJS.xk],null),jG=$CLJS.Wg(iG);
$CLJS.Y(nha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid time extraction unit"],null)],null),jG));var kG=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.WF,$CLJS.TF,$CLJS.Ck,$CLJS.Kk],null),lG=$CLJS.Wg(kG);$CLJS.Y(rha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid time truncation unit"],null)],null),lG));$CLJS.mG=$CLJS.Wf.j($CLJS.xf,$CLJS.tk.o(),$CLJS.bf.g(kG,iG));tha=$CLJS.Wg($CLJS.mG);
$CLJS.Y(mha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid time bucketing unit"],null)],null),tha));$CLJS.nG=$CLJS.Wf.j($CLJS.xf,$CLJS.tk.o(),$CLJS.bf.l(kG,fG,$CLJS.H([iG,dG])));uha=$CLJS.Wg($CLJS.nG);$CLJS.Y($CLJS.VF,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid datetime bucketing unit"],null)],null),$CLJS.nG));var vha=$CLJS.Yd.g(uha,$CLJS.Oh);
$CLJS.Y($CLJS.bG,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid temporal bucketing unit"],null)],null),vha));$CLJS.oG=$CLJS.Ws.g(gG,lG);$CLJS.Y($CLJS.YF,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid datetime truncation unit"],null)],null),$CLJS.oG));$CLJS.pG=$CLJS.Ws.g(eG,jG);$CLJS.Y(lha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid datetime extraction unit"],null)],null),$CLJS.pG));
var qG=$CLJS.Yd.g(gG,$CLJS.Wh);$CLJS.Y($CLJS.cG,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid date interval unit"],null)],null),qG));$CLJS.Y($CLJS.ZF,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid time interval unit"],null)],null),lG));var wha=$CLJS.Ws.g(qG,lG);$CLJS.Y($CLJS.dD,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid datetime interval unit"],null)],null),wha));
$CLJS.Y(qha,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.aG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.di,$CLJS.bG],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null));