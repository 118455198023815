var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.schema.common.js");require("./metabase.mbql.predicates.js");require("./metabase.mbql.schema.js");require("./metabase.mbql.schema.helpers.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var qma,AU;$CLJS.wU=function(a){return a instanceof Error?a.message:null};
qma=function(a,b,c){if($CLJS.Wd(c)){var d=$CLJS.Ne.g($CLJS.U,$CLJS.cf.g(a,c));return b.h?b.h(d):b.call(null,d)}return $CLJS.uf(c)?(d=new $CLJS.Sf(function(){var e=$CLJS.Bb(c);return a.h?a.h(e):a.call(null,e)}(),function(){var e=$CLJS.Cb(c);return a.h?a.h(e):a.call(null,e)}()),b.h?b.h(d):b.call(null,d)):$CLJS.zd(c)?(d=$CLJS.ch($CLJS.cf.g(a,c)),b.h?b.h(d):b.call(null,d)):$CLJS.ud(c)?(d=$CLJS.cb(function(e,f){return $CLJS.Yd.g(e,a.h?a.h(f):a.call(null,f))},c,c),b.h?b.h(d):b.call(null,d)):$CLJS.qd(c)?
(d=$CLJS.Wf.g($CLJS.gd(c),$CLJS.cf.g(a,c)),b.h?b.h(d):b.call(null,d)):b.h?b.h(c):b.call(null,c)};$CLJS.yU=function(a){function b(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return"string"===typeof d?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mh.h(d),c],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null)}return $CLJS.xU(function(c){return $CLJS.td(c)?$CLJS.Wf.g($CLJS.N,$CLJS.cf.g(b,c)):c},a)};
$CLJS.zU=function(a){var b=new $CLJS.Ca;for(a=$CLJS.y(a);;)if(null!=a)b=b.append($CLJS.p.h($CLJS.z(a))),a=$CLJS.B(a);else return b.toString()};$CLJS.BU=function(a,b,c){a=$CLJS.Gz(a,/''/,"'");var d=$CLJS.Ns(a,AU);d=$CLJS.E.g($CLJS.D(d),1)?new $CLJS.P(null,2,5,$CLJS.Q,[a,""],null):d;a=$CLJS.eh(/.*\{0\}.*/,a);return $CLJS.Gs.ngettext($CLJS.Gs.msgid($CLJS.uk(d),$CLJS.n(a)?c:""),$CLJS.Gz($CLJS.Gz(b,/''/,"'"),AU,$CLJS.p.h(c)),c)};
$CLJS.CU=function(a,b){return $CLJS.cb(function(c,d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.Dd(a,e)?$CLJS.R.j(c,d,$CLJS.K.g(a,e)):c},$CLJS.Ne.j($CLJS.Tj,a,$CLJS.Ng(b)),b)};$CLJS.DU=function(a,b,c){return $CLJS.td(c)?$CLJS.df(function(d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);e=$CLJS.Yd.g(b,e);return a.g?a.g(e,d):a.call(null,e,d)},$CLJS.H([c])):$CLJS.sd(c)?$CLJS.df($CLJS.Qe(a,$CLJS.z(c)instanceof $CLJS.M?$CLJS.Yd.g(b,$CLJS.z(c)):b),$CLJS.H([c])):null};
$CLJS.xU=function xU(a,b){return qma($CLJS.Qe(xU,a),a,b)};AU=/\{0\}/;$CLJS.EU=new $CLJS.M(null,"unique-alias-fn","unique-alias-fn",32555420);$CLJS.FU=new $CLJS.M(null,"binning-strategy","binning-strategy",2063329158);$CLJS.GU=new $CLJS.M("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);$CLJS.HU=new $CLJS.M(null,"name-key-fn","name-key-fn",-1634839199);var rma=$CLJS.zg([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lI,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WH,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aB,$CLJS.$H],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aB,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jI,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eI,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gI,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PH,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hI,null],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.aB,$CLJS.TH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aB,$CLJS.dI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VH,null],null)],[$CLJS.zk,$CLJS.XF,$CLJS.mI,$CLJS.ZH,$CLJS.xk,$CLJS.Gk,$CLJS.Dk,$CLJS.Jk,$CLJS.Ak,$CLJS.ZH,$CLJS.nI,$CLJS.UF]),JU;$CLJS.Wg($CLJS.cf.g($CLJS.z,$CLJS.Ng(rma)));var IU,sma=$CLJS.Se($CLJS.N),tma=$CLJS.Se($CLJS.N),uma=$CLJS.Se($CLJS.N),vma=$CLJS.Se($CLJS.N),wma=$CLJS.K.j($CLJS.N,$CLJS.Ni,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
IU=new $CLJS.Ph($CLJS.qh.g("metabase.mbql.util","negate*"),$CLJS.z,wma,sma,tma,uma,vma);IU.m(null,$CLJS.gr,function(a){$CLJS.I(a,0,null);return $CLJS.I(a,1,null)});IU.m(null,$CLJS.kr,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);return $CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.er],null),$CLJS.cf.g(IU,a))});IU.m(null,$CLJS.er,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);return $CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kr],null),$CLJS.cf.g(IU,a))});
IU.m(null,$CLJS.Iq,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AF,b,a],null)});IU.m(null,$CLJS.AF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Iq,b,a],null)});IU.m(null,$CLJS.Bq,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hq,b,a],null)});
IU.m(null,$CLJS.Fq,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dq,b,a],null)});IU.m(null,$CLJS.Dq,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fq,b,a],null)});IU.m(null,$CLJS.Hq,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bq,b,a],null)});
IU.m(null,$CLJS.LE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fq,b,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bq,b,a],null)],null)});IU.m(null,$CLJS.iF,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,a],null)});IU.m(null,$CLJS.aF,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,a],null)});
IU.m(null,$CLJS.uF,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,a],null)});
JU=function JU(a){return $CLJS.td(a)?$CLJS.Be($CLJS.Wf.g($CLJS.N,function(){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.wd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);a:for(var t=0;;)if(t<l){var u=$CLJS.id(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=JU.h?JU.h(u):JU.call(null,u);null!=u&&m.add(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null));t+=1}else{k=!0;break a}return k?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}k=$CLJS.z(f);
m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=JU.h?JU.h(k):JU.call(null,k);if(null!=k)return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[m,k],null),e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null},null,null)}(a)}())):$CLJS.sd(a)?$CLJS.Be($CLJS.Wf.g($CLJS.gd(a),$CLJS.ff($CLJS.Sa,$CLJS.cf.g(JU,a)))):a};
$CLJS.KU=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var k=Array(arguments.length-2);f<k.length;)k[f]=arguments[f+2],++f;f=new $CLJS.w(k,0,null)}return b.call(this,c,d,f)}function b(c,d,e){var f=$CLJS.I(c,0,null),k=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);d=$CLJS.Be(JU($CLJS.Ne.j(d,c,e)));return $CLJS.n(d)?new $CLJS.P(null,3,5,$CLJS.Q,[f,k,d],null):$CLJS.E.g(f,$CLJS.jF)?new $CLJS.P(null,3,5,$CLJS.Q,[f,k,null],null):new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}a.A=2;a.B=function(c){var d=
$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.Hc(c);return b(d,e,c)};a.l=b;return a}();