var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var FK,HK,JK,MK,PK;$CLJS.AK=function(a){return $CLJS.Hh($CLJS.q($CLJS.gB),a,$CLJS.nF)};$CLJS.BK=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.CK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);$CLJS.DK=new $CLJS.M(null,"column-ref","column-ref",2018188376);$CLJS.EK=new $CLJS.M(null,"object-id","object-id",-754527291);FK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);
$CLJS.GK=new $CLJS.M(null,"operators","operators",-2064102509);HK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.IK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);JK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.KK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.LK=new $CLJS.M(null,"dimensions","dimensions",-254818097);
MK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.NK=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.OK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);PK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.QK=new $CLJS.M(null,"null","null",-180137709);
$CLJS.RK=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.Y(HK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.vD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.bG],null)],null)],null));$CLJS.Y(PK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,HK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.zD],null)],null)],null));$CLJS.Y(JK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.jF],null),PK,$CLJS.qD],null));
$CLJS.Y(MK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.jF],null),HK,$CLJS.iK],null));
$CLJS.JF.g($CLJS.jF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.jF],null),HK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.iK,$CLJS.qD],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.vi,function(a){return $CLJS.sd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.XA(a)):null},$CLJS.ts,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.lA,MK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kA,JK],null)],null)],null));$CLJS.pE($CLJS.jF,$CLJS.nF);$CLJS.KD.m(null,$CLJS.jF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.ni,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.YD});$CLJS.wE($CLJS.qA,$CLJS.H([$CLJS.qD]));$CLJS.KD.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.ni,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.YD});
$CLJS.pE($CLJS.qA,$CLJS.nF);$CLJS.Y(FK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.vD,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.qD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.qD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CK,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.qD],null)],null)],null));
$CLJS.JF.g($CLJS.ZE,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.ZE],null),FK,$CLJS.sj],null));$CLJS.KD.m(null,$CLJS.ZE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.ni,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.YD});$CLJS.pE($CLJS.ZE,$CLJS.nF);$CLJS.wE($CLJS.HE,$CLJS.H([$CLJS.Zr,$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.NJ],null)],null)]));$CLJS.pE($CLJS.HE,$CLJS.nF);
$CLJS.wE($CLJS.eF,$CLJS.H([$CLJS.Zr,$CLJS.YD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.LJ],null)],null)]));$CLJS.pE($CLJS.eF,$CLJS.nF);
$CLJS.Y($CLJS.nF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.MD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.Es,function(){return["Valid reference, must be one of these clauses: ",$CLJS.hs(", ",$CLJS.Cz($CLJS.Ed,$CLJS.vk.g($CLJS.q($CLJS.gB),$CLJS.nF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.AK(a)}],null)],null));