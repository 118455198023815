var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var tG,uG,wG,xG,xha,yha,zha,yG,vG;$CLJS.rG=function(a,b){return $CLJS.cc($CLJS.cb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.Zf.j(c,e,$CLJS.Yd.g($CLJS.K.j(c,e,$CLJS.xf),d))},$CLJS.ac($CLJS.N),b))};$CLJS.sG=function(a,b){return $CLJS.cb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Oc(d):null},null,b)};
tG=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.Dz(b,$CLJS.yk)?$CLJS.cG:$CLJS.Dz(b,$CLJS.Mk)?$CLJS.ZF:$CLJS.Dz(b,$CLJS.Ek)?$CLJS.dD:null;return $CLJS.n(b)?$CLJS.oE(b,a):!0};
uG=function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);$CLJS.z(a);a=$CLJS.B(a);a=$CLJS.rG(function(d){return $CLJS.Dz($CLJS.LD(d),$CLJS.bC)},a);var b=$CLJS.O(a);a=$CLJS.K.g(b,!1);b=$CLJS.K.g(b,!0);if($CLJS.Zj.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.LD($CLJS.z(a));return $CLJS.Me(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(tG(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
wG=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,1,[$CLJS.ts,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.vD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,vG],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.UD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,vG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.Es,function(b){b=$CLJS.O(b);b=$CLJS.K.g(b,$CLJS.bj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(uG(b))].join("")}],null),$CLJS.Oe(uG)],null)],null)};
xG=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.ts,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.vD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Sq,new $CLJS.h(null,1,[$CLJS.qi,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hE],null)],null)],null)],null)};
xha=function(a){return $CLJS.Md($CLJS.rE,$CLJS.cf.g(function(b){var c=$CLJS.LD(b),d=$CLJS.Dz(c,$CLJS.YD);b=d?$CLJS.oE($CLJS.nF,b):d;return $CLJS.n(b)?$CLJS.lj:c},a))};yha=function(a){a=$CLJS.sG(function(b){return!$CLJS.Dz(b,$CLJS.bC)},$CLJS.cf.g($CLJS.LD,a));return $CLJS.Dz(a,$CLJS.YD)?$CLJS.qj:a};
zha=function(a,b){return $CLJS.n($CLJS.Me(function(c){return $CLJS.Dz($CLJS.LD(c),$CLJS.bC)},b))?yha(b):$CLJS.E.g(a,$CLJS.Zr)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Le(function(c){return $CLJS.Dz($CLJS.LD(c),$CLJS.yk)},b)||$CLJS.Le(function(c){return $CLJS.Dz($CLJS.LD(c),$CLJS.Ek)},b))?$CLJS.bC:xha(b)};yG=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.zG=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);vG=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.Y(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.UD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.V,vG],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.ts,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=$CLJS.LD(a);return $CLJS.Le(function(d){return tG(d,c)},b)}],null)],null));
$CLJS.Y(yG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Sq,new $CLJS.h(null,1,[$CLJS.qi,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hE],null)],null)],null));
var Aha=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.ts,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.Zr],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.vD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.UD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.UD],null)],null)],
null);$CLJS.JF.g($CLJS.Kq,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,wG($CLJS.Kq),xG($CLJS.Kq)],null));$CLJS.JF.g($CLJS.Zr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.er,wG($CLJS.Zr),Aha,xG($CLJS.Zr)],null));$CLJS.yE($CLJS.Lq,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,yG],null)]));$CLJS.yE($CLJS.EF,$CLJS.H([$CLJS.Zr,$CLJS.MC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,yG],null)]));
for(var AG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Kq,$CLJS.Zr,$CLJS.Lq],null)),BG=null,CG=0,DG=0;;)if(DG<CG){var Bha=BG.X(null,DG);$CLJS.pE(Bha,$CLJS.zG);DG+=1}else{var EG=$CLJS.y(AG);if(EG){var FG=EG;if($CLJS.wd(FG)){var GG=$CLJS.ic(FG),Cha=$CLJS.jc(FG),Dha=GG,Eha=$CLJS.D(GG);AG=Cha;BG=Dha;CG=Eha}else{var Fha=$CLJS.z(FG);$CLJS.pE(Fha,$CLJS.zG);AG=$CLJS.B(FG);BG=null;CG=0}DG=0}else break}
$CLJS.KD.m(null,$CLJS.zG,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);$CLJS.z(b);b=$CLJS.B(b);return zha(a,b)});$CLJS.wE($CLJS.JE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hE],null)],null)]));$CLJS.pE($CLJS.JE,$CLJS.bE);
for(var HG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GF,$CLJS.AE,$CLJS.XE],null)),IG=null,JG=0,KG=0;;)if(KG<JG){var Gha=IG.X(null,KG);$CLJS.wE(Gha,$CLJS.H([$CLJS.Zr,$CLJS.MC,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hE],null)],null)]));KG+=1}else{var LG=$CLJS.y(HG);if(LG){var MG=LG;if($CLJS.wd(MG)){var NG=$CLJS.ic(MG),Hha=$CLJS.jc(MG),Iha=NG,Jha=$CLJS.D(NG);HG=Hha;IG=Iha;JG=Jha}else{var Kha=$CLJS.z(MG);$CLJS.wE(Kha,$CLJS.H([$CLJS.Zr,$CLJS.MC,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hE],null)],null)]));HG=$CLJS.B(MG);IG=null;JG=0}KG=0}else break}
for(var OG=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EE,$CLJS.hF,$CLJS.oF],null)),PG=null,QG=0,RG=0;;)if(RG<QG){var Lha=PG.X(null,RG);$CLJS.wE(Lha,$CLJS.H([$CLJS.Zr,$CLJS.Wi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hE],null)],null)]));RG+=1}else{var SG=$CLJS.y(OG);if(SG){var TG=SG;if($CLJS.wd(TG)){var UG=$CLJS.ic(TG),Mha=$CLJS.jc(TG),Nha=UG,Oha=$CLJS.D(UG);OG=Mha;PG=Nha;QG=Oha}else{var Pha=$CLJS.z(TG);$CLJS.wE(Pha,$CLJS.H([$CLJS.Zr,$CLJS.Wi,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hE],null)],null)]));OG=$CLJS.B(TG);PG=null;QG=0}RG=0}else break}$CLJS.wE($CLJS.GE,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hE],null)],null)]));
$CLJS.KD.m(null,$CLJS.GE,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.Dz($CLJS.LD(b),$CLJS.Wi)&&$CLJS.Dz($CLJS.LD(a),$CLJS.Wi)?$CLJS.Wi:$CLJS.MC});