var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var VK,WK,XK,nja,oja,aL,pja,dL,eL,fL,gL,qja,hL,iL,kL,lL,YK,mL,rja,nL,sja,oL,pL,qL,tja,rL,sL,uja,tL,vja,uL,vL,wja,xL,xja,yja,zja,zL,AL,BL,CL,DL,EL,FL,GL,Aja,HL,IL,JL,LL,ML,Bja,NL,OL,PL,QL,Cja,RL,SL,TL,UL,Dja,VL,WL,XL,YL,Eja,ZL,Fja,$L,aM,bM,Gja,cM,dM,eM,fM,hM,iM,Hja,jM,kM,Ija,lM,mM,nM,oM,pM,Jja,qM,Kja,rM,sM,uM,Lja,vM,wM,xM,Mja,AM,BM,Nja,Oja,CM,EM,FM,GM,Pja,JM,KM,Qja,Rja,Sja,LM,MM,NM,OM,PM,QM,Tja,RM,Uja,SM,TM,VM,XM,YM,ZM,$M,Vja,Wja,bN,Xja,Yja,cN,dN,eN,fN,gN,Zja,hN,$ja,aka,iN,jN,kN,lN,mN,
bka,cka,dka,nN,eka,oN,fka,qN,rN,gka,tN,uN,vN,wN,hka,zN,ika,jka,AN,BN,CN,kka,DN,EN,FN,GN,lka,IN,JN,KN,LN,MN,mka,NN,ON,nka,PN,QN,RN,SN,TN,UN,WN,oka,XN,pka,YN,ZN,$N,qka,rka,ska,bO,cO,dO,tka,eO,fO,gO,hO,uka,vka,jO,wka,xka,kO,lO,mO,nO,oO,yka,pO,qO,rO,sO,zka,tO,uO,vO,wO,yO,Aka,zO,AO,Bka,BO,CO,DO,Cka,EO,Dka,GO,Eka,HO,IO,JO,KO,LO,MO,NO,Fka,Gka,Hka,OO,Ika,PO,QO,Jka,Kka,RO,SO,TO,Lka,UO,Mka,VO,Nka,Oka,WO,Pka,Qka,Rka,ZO,$O,Ska,aP,bP,dP,Tka,eP,Uka,fP,gP,hP,Vka,iP,jP,kP,lP,Wka,nP,oP,Xka,pP,qP,rP,sP,tP,uP,vP,wP,
Yka,xP,yP,Zka,zP,$ka,AP,BP,CP,ala,DP,EP,bla,cla,FP,GP,HP,dla,ela,IP,fla,gla,hla,KP,ila,MP,jla,kla,NP,OP,lla,mla,nla,PP,QP,RP,SP,TP,UP,VP,ola,WP,XP,pla,YP,qla,rla,sla,ZP,$P,aQ,tla,bQ,cQ,ula,dQ,vla,eQ,wla,xla,gQ,hQ,iQ,jQ,kQ,yla,lQ,mQ,zla,nQ,oQ,pQ,qQ,rQ,sQ,tQ,uQ,Ala,vQ,wQ,yQ,zQ,AQ,BQ,CQ,DQ,EQ,FQ,GQ,HQ,Bla,IQ,JQ,KQ,LQ,MQ,Cla,NQ,OQ,PQ,QQ,Dla,SQ,Ela,Fla,UQ,Gla,VQ,Hla,WQ,Ila,Jla,XQ,Kla,YQ,$Q,aR,Lla,Mla,eR,fR,Nla,Ola,gR,hR,iR,jR,kR,Pla,lR,mR,oR,pR,Qla;
$CLJS.UK=function(a){var b=$CLJS.Cn.g(a,null),c=$CLJS.lE(b,$CLJS.zr,function(d){return $CLJS.Im(d,$CLJS.xf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.Qk,b,$CLJS.bj,k,$CLJS.Hr,l],null):null}function e(k){return f.j(k,$CLJS.xf,$CLJS.xf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
VK=function(a,b){return $CLJS.td(a)?$CLJS.K.g(a,b):a};WK=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.Lm($CLJS.Cn.g($CLJS.Qk.h(a),null));if($CLJS.n(b)){var e=VK($CLJS.Es.h(b),c);$CLJS.n(e)?(e=$CLJS.Ap(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:VK($CLJS.ts.h(b),c)}return null};XK=function(a,b){return $CLJS.rd(a)||$CLJS.hl(a)?$CLJS.K.g(a,b):$CLJS.sd(a)?$CLJS.K.g($CLJS.yf(a),b):null};
nja=function(a,b){a=$CLJS.O(a);var c=$CLJS.K.g(a,$CLJS.Qk),d=$CLJS.K.g(a,$CLJS.Pi);b=$CLJS.O(b);var e=$CLJS.K.j(b,$CLJS.Hr,$CLJS.lda),f=$CLJS.K.j(b,$CLJS.gda,!0),k=$CLJS.K.g(b,$CLJS.Fs),l=$CLJS.K.j(b,$CLJS.Cs,$CLJS.As),m=WK(a,$CLJS.Cp.h(c),k,b);if($CLJS.n(m))return m;m=WK(a,$CLJS.Tr.h(c),k,b);if($CLJS.n(m))return m;m=WK(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=WK(a,function(){var t=$CLJS.on.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=WK(a,$CLJS.Cp.h(c),
l,b);if($CLJS.n(m))return m;m=WK(a,$CLJS.Tr.h(c),l,b);if($CLJS.n(m))return m;d=WK(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=WK(a,function(){var t=$CLJS.on.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?WK(a,e.h?e.h($CLJS.ys):e.call(null,$CLJS.ys),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?WK(a,e.h?e.h($CLJS.ys):e.call(null,$CLJS.ys),l,b):f};
oja=function(a,b,c){a=$CLJS.Q;var d=$CLJS.Wf.g;var e=$CLJS.Nk.h(b);var f=$CLJS.O(b);var k=$CLJS.K.g(f,$CLJS.Qk),l=$CLJS.O(c);f=$CLJS.K.g(l,$CLJS.Fs);l=$CLJS.K.j(l,$CLJS.Cs,$CLJS.As);k=$CLJS.Cp.h(k);f=VK($CLJS.Bs.h(k),f);f=$CLJS.n(f)?f:VK($CLJS.Bs.h(k),l);d=d.call($CLJS.Wf,e,f);return new $CLJS.P(null,2,5,a,[d,nja(b,c)],null)};
$CLJS.ZK=function(a){var b=$CLJS.O(a),c=$CLJS.K.g(b,$CLJS.bj);a=$CLJS.K.g(b,$CLJS.Hr);var d=$CLJS.O(null),e=$CLJS.K.j(d,$CLJS.hda,$CLJS.xi),f=$CLJS.K.j(d,$CLJS.jda,oja);return $CLJS.n(a)?$CLJS.cb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.xi,m);l=e.h?e.h(l):e.call(null,l);return YK(k,c,t,l)},null,a):null};
aL=function(a,b,c){var d=$CLJS.Bz($CLJS.q($CLJS.$C),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.zh.v($CLJS.$C,$CLJS.$K,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.bL=function(a){return aL($CLJS.Ij,a,function(){return $CLJS.mE.h(a)})};pja=function(){var a=cL;return aL($CLJS.zr,a,function(){var b=$CLJS.mE.h(a),c=$CLJS.UK(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
dL=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,$CLJS.he(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,a],null):a],null)};eL=function(a){if($CLJS.vd(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.S:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,dL(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,dL(c)],null);default:return dL(a)}}else return dL(a)};
fL=function(a,b){var c=$CLJS.vd(b);return c?(c=$CLJS.z(b)instanceof $CLJS.M)?$CLJS.qd(a)?(b=$CLJS.z(b),a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c:c};
gL=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.ts,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Qe(fL,a)],null),$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wq,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,a],null)],null)],null),function(){return function e(d){return new $CLJS.ie(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.wd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.id(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,eL(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[m,eL(k)],null),e($CLJS.Hc(f)))}return null}},null,null)}($CLJS.Wr(2,2,b))}())],null)};qja=function(a){return $CLJS.vd(a)&&$CLJS.z(a)instanceof $CLJS.M?$CLJS.z(a):null};
hL=function(a){return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.vi,qja,$CLJS.ts,["valid instance of one of these MBQL clauses: ",$CLJS.hs(", ",$CLJS.cf.g($CLJS.z,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.wd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.id(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.he(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.he(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,f],null):f],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())};
iL=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.TK],null)],null)};$CLJS.jL={};kL={};lL={};$CLJS.$K=function $K(a,b,c){var e=$CLJS.y(b);b=$CLJS.z(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.K.g(a,b);c=$K.j?$K.j(k,f,c):$K.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
YK=function YK(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.z(f);var k=$CLJS.B(f),l=XK(b,c);f=$CLJS.n(a)?a:$CLJS.sd(b)?$CLJS.xf:$CLJS.ud(b)?$CLJS.N:$CLJS.gd(b);return $CLJS.n($CLJS.n(c)?$CLJS.zs.h($CLJS.md(f)):c)?a:$CLJS.n(c)?(b=XK(f,c),d=YK.v?YK.v(b,l,k,d):YK.call(null,b,l,k,d),$CLJS.ml(c)&&$CLJS.sd(f)&&c>$CLJS.D(f)&&(b=$CLJS.Ze(c-$CLJS.D(f),null),b=$CLJS.bf.g(f,b),f=null==f||$CLJS.zd(f)?b:$CLJS.Wf.g($CLJS.gd(f),b)),null==f||$CLJS.hl(f)?$CLJS.R.j(f,c,d):$CLJS.rd(f)?$CLJS.Yd.g(f,d):$CLJS.Ne.g($CLJS.U,$CLJS.R.j($CLJS.yf(f),
c,d))):$CLJS.td(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ida],null),YK.v?YK.v(f,b,c,d):YK.call(null,f,b,c,d)):$CLJS.n($CLJS.zs.h($CLJS.md(f)))?$CLJS.Yd.g(f,d):$CLJS.vd($CLJS.Be(f))?f:$CLJS.ld(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.zs,!0],null))};mL=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);rja=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
nL=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);sja=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);oL=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);pL=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);qL=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
tja=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);rL=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);sL=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);uja=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);tL=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
vja=new $CLJS.M(null,"from","from",1815293044);uL=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);vL=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);wja=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.wL=new $CLJS.M(null,"snippet-name","snippet-name",819240328);xL=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
xja=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);yja=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.yL=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);zja=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);zL=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
AL=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);BL=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);CL=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);DL=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);EL=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
FL=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);GL=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Aja=new $CLJS.M(null,"lon-max","lon-max",1590224717);HL=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);IL=new $CLJS.r(null,"stddev","stddev",775056588,null);JL=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.KL=new $CLJS.M(null,"snippet","snippet",953581994);LL=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);ML=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Bja=new $CLJS.M(null,"lat-field","lat-field",-830652957);NL=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);OL=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
PL=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);QL=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Cja=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);RL=new $CLJS.M("location","country","location/country",1666636202);SL=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
TL=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);UL=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Dja=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);VL=new $CLJS.M(null,"unary","unary",-989314568);WL=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
XL=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);YL=new $CLJS.r(null,"ceil","ceil",-184398425,null);Eja=new $CLJS.M(null,"lon-min","lon-min",-787291357);ZL=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);Fja=new $CLJS.M(null,"match","match",1220059550);$L=new $CLJS.r(null,"count-where","count-where",2025939247,null);aM=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
bM=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Gja=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);cM=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);dM=new $CLJS.r(null,"sum","sum",1777518341,null);eM=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);fM=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.gM=new $CLJS.M("date","range","date/range",1647265776);hM=new $CLJS.r(null,"between","between",-1523336493,null);iM=new $CLJS.M(null,"clause-form","clause-form",1820463737);Hja=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);jM=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);kM=new $CLJS.r(null,"field","field",338095027,null);Ija=new $CLJS.M(null,"segment-id","segment-id",1810133590);
lM=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);mM=new $CLJS.r(null,"not-null","not-null",313812992,null);nM=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);oM=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);pM=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Jja=new $CLJS.M(null,"template-tag","template-tag",310841038);
qM=new $CLJS.M(null,"invalid","invalid",412869516);Kja=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);rM=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);sM=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.tM=new $CLJS.M(null,"context","context",-830191113);uM=new $CLJS.r(null,"get-year","get-year",704520253,null);
Lja=new $CLJS.M(null,"format-rows?","format-rows?",992129486);vM=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);wM=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);xM=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.yM=new $CLJS.M(null,"parameters","parameters",-1229919748);Mja=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.zM=new $CLJS.M(null,"card","card",-1430355152);AM=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);BM=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);Nja=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Oja=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);CM=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.DM=new $CLJS.M("date","month-year","date/month-year",1948031290);
EM=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);FM=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);GM=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.HM=new $CLJS.M(null,"joins","joins",1033962699);Pja=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.IM=new $CLJS.M(null,"source-field","source-field",933829534);JM=new $CLJS.r(null,"Field","Field",430385967,null);
KM=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);Qja=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);Rja=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);Sja=new $CLJS.M(null,"items","items",1031954938);LM=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);MM=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
NM=new $CLJS.M(null,"more","more",-2058821800);OM=new $CLJS.M(null,"first-clause","first-clause",-20953491);PM=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);QM=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);Tja=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);RM=new $CLJS.r(null,"contains","contains",-1977535957,null);
Uja=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);SM=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);TM=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.UM=new $CLJS.M(null,"widget-type","widget-type",1836256899);VM=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.WM=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
XM=new $CLJS.r(null,"is-null","is-null",-356519403,null);YM=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);ZM=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);$M=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);Vja=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.aN=new $CLJS.M(null,"required","required",1807647006);Wja=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
bN=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);Xja=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);Yja=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);cN=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);dN=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);eN=new $CLJS.M("string","contains","string/contains",1602423827);fN=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
gN=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);Zja=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);hN=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);$ja=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);aka=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
iN=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);jN=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);kN=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);lN=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);mN=new $CLJS.r(null,"share","share",1051097594,null);
bka=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);cka=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);dka=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);nN=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);eka=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
oN=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);fka=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.pN=new $CLJS.M(null,"collection","collection",-683361892);qN=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);rN=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.sN=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);gka=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);tN=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);uN=new $CLJS.r(null,"metric","metric",2049329604,null);vN=new $CLJS.r(null,"concat","concat",-467652465,null);wN=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.xN=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
hka=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.yN=new $CLJS.M("date","relative","date/relative",25987732);zN=new $CLJS.M("location","city","location/city",-1746973325);ika=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);jka=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);AN=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);BN=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
CN=new $CLJS.M("number","between","number/between",97700581);kka=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);DN=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);EN=new $CLJS.r(null,"sqrt","sqrt",370479598,null);FN=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);GN=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.HN=new $CLJS.M(null,"semantic_type","semantic_type",272485089);lka=new $CLJS.M(null,"metric-id","metric-id",-686486942);IN=new $CLJS.r(null,"*","*",345799209,null);JN=new $CLJS.r(null,"+","+",-740910886,null);KN=new $CLJS.r(null,"-","-",-471816912,null);LN=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);MN=new $CLJS.M(null,"allowed-for","allowed-for",122724334);mka=new $CLJS.M(null,"question","question",-1411720117);NN=new $CLJS.r(null,"asc","asc",1997386096,null);
ON=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);nka=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);PN=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);QN=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);RN=new $CLJS.r(null,"\x3c","\x3c",993667236,null);SN=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);TN=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
UN=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.VN=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);WN=new $CLJS.M("string","ends-with","string/ends-with",302681156);oka=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);XN=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);pka=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
YN=new $CLJS.r(null,"and","and",668631710,null);ZN=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);$N=new $CLJS.r(null,"round","round",-645002441,null);qka=new $CLJS.M(null,"to","to",192099007);$CLJS.aO=new $CLJS.M("date","single","date/single",1554682003);rka=new $CLJS.M(null,"action-id","action-id",-1727958578);ska=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);bO=new $CLJS.r(null,"exp","exp",1378825265,null);
cO=new $CLJS.r(null,"Filter","Filter",-424893332,null);dO=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);tka=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);eO=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);fO=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);gO=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
hO=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.iO=new $CLJS.M(null,"source-table","source-table",-225307692);uka=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);vka=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);jO=new $CLJS.r(null,"floor","floor",-772394748,null);wka=new $CLJS.M(null,"middleware","middleware",1462115504);
xka=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);kO=new $CLJS.M(null,"requires-features","requires-features",-101116256);lO=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);mO=new $CLJS.M(null,"clause-name","clause-name",-996419059);nO=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);oO=new $CLJS.r(null,"now","now",-9994004,null);
yka=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);pO=new $CLJS.r(null,"not","not",1044554643,null);qO=new $CLJS.r(null,"avg","avg",1837937727,null);rO=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);sO=new $CLJS.M(null,"max-results","max-results",-32858165);zka=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);tO=new $CLJS.r(null,"case","case",-1510733573,null);
uO=new $CLJS.r(null,"distinct","distinct",-148347594,null);vO=new $CLJS.r(null,"get-second","get-second",-425414791,null);wO=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.xO=new $CLJS.M(null,"join-alias","join-alias",1454206794);yO=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Aka=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
zO=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);AO=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Bka=new $CLJS.M(null,"original","original",-445386197);BO=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);CO=new $CLJS.r(null,"abs","abs",1394505050,null);DO=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Cka=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
EO=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Dka=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.FO=new $CLJS.M(null,"date","date",-1463434462);GO=new $CLJS.M(null,"second-clause","second-clause",-461435645);Eka=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);HO=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
IO=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);JO=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);KO=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);LO=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);MO=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);NO=new $CLJS.r(null,"or","or",1876275696,null);
Fka=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Gka=new $CLJS.M(null,"constraints","constraints",422775616);Hka=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);OO=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Ika=new $CLJS.M(null,"csv-download","csv-download",2141432084);PO=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
QO=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);Jka=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Kka=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);RO=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);SO=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
TO=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);Lka=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);UO=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);Mka=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);VO=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);Nka=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
Oka=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);WO=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);Pka=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);Qka=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);Rka=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.XO=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.YO=new $CLJS.M(null,"database","database",1849087575);ZO=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);$O=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);Ska=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);aP=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
bP=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.cP=new $CLJS.M(null,"expressions","expressions",255689909);dP=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);Tka=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);eP=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
Uka=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);fP=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);gP=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);hP=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);Vka=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);
iP=new $CLJS.r(null,"get-day","get-day",1768100384,null);jP=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);kP=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);lP=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.mP=new $CLJS.M(null,"native","native",-613060878);Wka=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);nP=new $CLJS.M(null,"page","page",849072397);
oP=new $CLJS.r(null,"length","length",-2065447907,null);Xka=new $CLJS.M(null,"dashboard","dashboard",-631747508);pP=new $CLJS.r(null,"get-week","get-week",752472178,null);qP=new $CLJS.r(null,"get-month","get-month",1271156796,null);rP=new $CLJS.r(null,"dimension","dimension",-2111181571,null);sP=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);tP=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
uP=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);vP=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);wP=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);Yka=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);xP=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);yP=new $CLJS.r(null,"substring","substring",-1513569493,null);
Zka=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);zP=new $CLJS.M(null,"internal","internal",-854870097);$ka=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);AP=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);BP=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
CP=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);ala=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);DP=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);EP=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);bla=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
cla=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);FP=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);GP=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);HP=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
dla=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);ela=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);IP=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.JP=new $CLJS.M(null,"template-tags","template-tags",1853115685);fla=new $CLJS.M(null,"public-question","public-question",629369976);gla=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
hla=new $CLJS.M(null,"binary","binary",-1802232288);KP=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);ila=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.LP=new $CLJS.M(null,"source-query","source-query",198004422);MP=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);jla=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
kla=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);NP=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);OP=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);lla=new $CLJS.M(null,"executed-by","executed-by",-739811161);mla=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
nla=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);PP=new $CLJS.M(null,"amount","amount",364489504);QP=new $CLJS.r(null,"percentile","percentile",1039342775,null);RP=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);SP=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);TP=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
UP=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);VP=new $CLJS.r(null,"trim","trim",-1880116002,null);ola=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);WP=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);XP=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);pla=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
YP=new $CLJS.M("string","\x3d","string/\x3d",983744235);qla=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);rla=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);sla=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);ZP=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);$P=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
aQ=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);tla=new $CLJS.M(null,"lat-min","lat-min",1630784161);bQ=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);cQ=new $CLJS.r(null,"inside","inside",-681932758,null);ula=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);dQ=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
vla=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);eQ=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);wla=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);xla=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.fQ=new $CLJS.M(null,"card-id","card-id",-1770060179);gQ=new $CLJS.M(null,"variadic","variadic",882626057);
hQ=new $CLJS.r(null,"upper","upper",1886775433,null);iQ=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);jQ=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);kQ=new $CLJS.r(null,"optional","optional",-600484260,null);yla=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);lQ=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
mQ=new $CLJS.M(null,"sugar","sugar",-73788488);zla=new $CLJS.M(null,"lat-max","lat-max",841568226);nQ=new $CLJS.r(null,"power","power",702679448,null);oQ=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);pQ=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);qQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
rQ=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);sQ=new $CLJS.r(null,"median","median",-2084869638,null);tQ=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);uQ=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Ala=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);vQ=new $CLJS.M(null,"y","y",-1757859776);wQ=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.xQ=new $CLJS.M(null,"binning","binning",1709835866);yQ=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);zQ=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);AQ=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);BQ=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);CQ=new $CLJS.r(null,"Reference","Reference",2024574086,null);DQ=new $CLJS.M(null,"b","b",1482224470);
EQ=new $CLJS.M(null,"a","a",-2123407586);FQ=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);GQ=new $CLJS.r(null,"replace","replace",853943757,null);HQ=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Bla=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);IQ=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
JQ=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);KQ=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);LQ=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);MQ=new $CLJS.r(null,"segment","segment",675610331,null);Cla=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);NQ=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
OQ=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);PQ=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);QQ=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Dla=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.RQ=new $CLJS.M(null,"order-by","order-by",1527318070);SQ=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Ela=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Fla=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.TQ=new $CLJS.M(null,"condition","condition",1668437652);UQ=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Gla=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);VQ=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Hla=new $CLJS.M(null,"card-name","card-name",-2035606807);WQ=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Ila=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Jla=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);XQ=new $CLJS.r(null,"log","log",45015523,null);Kla=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
YQ=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.ZQ=new $CLJS.M(null,"database_type","database_type",-54700895);$Q=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);aR=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.bR=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.cR=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.dR=new $CLJS.M("date","all-options","date/all-options",549325958);
Lla=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);Mla=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);eR=new $CLJS.M("location","state","location/state",-114378652);fR=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);Nla=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);Ola=new $CLJS.M(null,"lon-field","lon-field",517872067);
gR=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);hR=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);iR=new $CLJS.M(null,"numeric","numeric",-1495594714);jR=new $CLJS.r(null,"variable","variable",1359185035,null);kR=new $CLJS.r(null,"lower","lower",-1534114948,null);Pla=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
lR=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);mR=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.nR=new $CLJS.M(null,"limit","limit",-1355822363);oR=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);pR=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);Qla=new $CLJS.M(null,"pulse","pulse",-244494476);var qR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.qD],null),rR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.zD],null),sR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.mD],null),tR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iD],null),uR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.uD],null),vR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iK],null),Rla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WJ],null),Sla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.PJ],null),wR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,
$CLJS.JH],null),xR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.NH],null),yR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.EH],null),zR=new $CLJS.Rg(null,new $CLJS.h(null,12,[$CLJS.bi,null,$CLJS.Lk,null,$CLJS.Ri,null,$CLJS.Oh,null,$CLJS.Ak,null,$CLJS.Xi,null,$CLJS.Jk,null,$CLJS.Gk,null,$CLJS.Wh,null,$CLJS.dj,null,$CLJS.ci,null,$CLJS.zk,null],null),null),AR=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.Kk,null,$CLJS.TF,null,$CLJS.Oh,null,$CLJS.xk,null,$CLJS.WF,null,$CLJS.Dk,null,$CLJS.Ck,null],null),null),
BR=$CLJS.Ws.g(zR,AR),Tla=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"date bucketing unit"],null)],null),zR),Ula=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"time bucketing unit"],null)],null),AR),CR=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"datetime bucketing unit"],null)],null),BR),DR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iI],null),Vla=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,
1,[$CLJS.ts,"temporal extract unit"],null),$CLJS.XF,$CLJS.zk,$CLJS.Jk,$CLJS.ZH,$CLJS.mI,$CLJS.nI,$CLJS.Gk,$CLJS.Ak,$CLJS.xk,$CLJS.Dk,$CLJS.UF],null),Wla=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"datetime-diff unit"],null),$CLJS.TF,$CLJS.Ck,$CLJS.Kk,$CLJS.Lk,$CLJS.Ri,$CLJS.Xi,$CLJS.bi,$CLJS.Wh],null),ER=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"temporal-extract week extraction mode"],null),$CLJS.TH,$CLJS.$H,$CLJS.dI],null),FR=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"relative-datetime unit"],null),$CLJS.Oh,$CLJS.Ck,$CLJS.Kk,$CLJS.Lk,$CLJS.Ri,$CLJS.Xi,$CLJS.bi,$CLJS.Wh],null),MR,Yla,UR,VR,WR,YR,ZR,$R,aS,NT,$la,OT,ama,bma,PT,cma,dma,ema;$CLJS.Y(XL,gL($CLJS.$A,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.Qu],null),$CLJS.pr],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,FR],null)])));var GR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,XL],null);
$CLJS.Y(BO,gL($CLJS.ZA,$CLJS.H(["n",$CLJS.pr,"unit",FR])));var Xla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,BO],null);
$CLJS.Y(rM,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"valid :absolute-datetime clause",$CLJS.vi,function(a){if($CLJS.Ra(fL($CLJS.XH,a)))a=qM;else{a=$CLJS.dd(a);var b=$CLJS.bL(wR);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.FO:$CLJS.aI}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[qM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.ts,"not an :absolute-datetime clause"],null),$CLJS.Pe(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FO,gL($CLJS.XH,
$CLJS.H(["date",wR,"unit",Tla]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aI,gL($CLJS.XH,$CLJS.H(["datetime",xR,"unit",CR]))],null)],null));var HR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,rM],null);$CLJS.Y(GM,gL($CLJS.Fx,$CLJS.H(["time",yR,"unit",Ula])));var IR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,GM],null),JR=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.er,new $CLJS.h(null,1,[$CLJS.ts,"date or datetime literal"],null),HR,xR,wR],null);
$CLJS.Y(rL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.er,new $CLJS.h(null,1,[$CLJS.ts,"time literal"],null),IR,yR],null));$CLJS.Y(gR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.er,new $CLJS.h(null,1,[$CLJS.ts,"temporal literal"],null),JR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,rL],null)],null));var KR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gR],null);
$CLJS.Y(pla,hL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof HR?new $CLJS.Cc(function(){return HR},$CLJS.ld(Qja,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,zP,$CLJS.Qi,$CLJS.Kj,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hP,"metabase/mbql/schema.cljc",69,$CLJS.XH,1,!0,168,168,$CLJS.Gc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(HR)?HR.H:null])):null));return $CLJS.n(a)?a:hP}(),HR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof GR?new $CLJS.Cc(function(){return GR},$CLJS.ld(Lla,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,
$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zO,"metabase/mbql/schema.cljc",29,$CLJS.$A,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$A,$CLJS.Ej,$CLJS.U($CLJS.mj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.Qu],null),$CLJS.pr],null)),$CLJS.di,$CLJS.U($CLJS.mj,$CLJS.U(kQ,XN))],null),$CLJS.Gc,"Schema for a valid relative-datetime clause.",
$CLJS.n(GR)?GR.H:null])):null));return $CLJS.n(a)?a:zO}(),GR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof IR?new $CLJS.Cc(function(){return IR},$CLJS.ld(Oka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,zP,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),YQ,"metabase/mbql/schema.cljc",27,$CLJS.Fx,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fx,$CLJS.Fx,$CLJS.U($CLJS.mj,ola),$CLJS.di,$CLJS.U($CLJS.mj,hka)],null),$CLJS.Gc,"Schema for a valid time clause.",$CLJS.n(IR)?IR.H:null])):null));return $CLJS.n(a)?a:YQ}(),IR],null)])));
$CLJS.Y(WQ,gL($CLJS.bj,$CLJS.H(["value",$CLJS.ur,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZQ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,qR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dC,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,rR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HN,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.dr,sR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,CR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,qR],null)],null)],null)],null)])));var LR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,WQ],null);$CLJS.Y(GN,gL($CLJS.qA,$CLJS.H(["expression-name",qR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,$CLJS.xj],null)])));
MR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,GN],null);
Yla=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.ts,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.K.g(c,$CLJS.BD);c=$CLJS.K.g(c,$CLJS.jD);return $CLJS.E.g(b,$CLJS.jD)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.ts,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.K.g(c,$CLJS.BD);c=$CLJS.K.g(c,$CLJS.AD);return $CLJS.E.g(b,$CLJS.AD)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,1,[$CLJS.ts,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BD,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"binning strategy"],null),$CLJS.jD,$CLJS.AD,$CLJS.Oh],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),tR],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.AD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.$k,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.ts,"bin width must be \x3e\x3d 0."],null),$CLJS.Oe($CLJS.ul)],null)],null)],null)],null)));$CLJS.NR=function NR(a){switch(arguments.length){case 1:return NR.h(arguments[0]);case 2:return NR.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.NR.h=function(a){var b=$CLJS.O(a);a=$CLJS.K.g(b,$CLJS.tA);b=$CLJS.K.g(b,$CLJS.pF);return $CLJS.NR.g(a,b)};$CLJS.NR.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.Dz(a,$CLJS.yk)?zR:$CLJS.Dz(a,$CLJS.Mk)?AR:$CLJS.Dz(a,$CLJS.Ek)?BR:null:null;return $CLJS.n(a)?$CLJS.Dd(a,b):!0};$CLJS.NR.A=2;
$CLJS.Y(lQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,1,[$CLJS.ts,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tA,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,rR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,vR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pF,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,CR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,qR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xQ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,Yla],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.ts,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.NR],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.ts,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Oe($CLJS.BD)],null)],null));
$CLJS.Y(NQ,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.ts,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.K.g(b,$CLJS.tA);return"string"===typeof c?b:!0}],null)],null)}(gL($CLJS.jF,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,vR,qR],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,lQ],null)],null)]))));var OR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,NQ],null);
$CLJS.Y(OO,hL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof MR?new $CLJS.Cc(function(){return MR},$CLJS.ld(SM,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CM,"metabase/mbql/schema.cljc",60,$CLJS.qA,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.qA,$CLJS.cR,$CLJS.U($CLJS.mj,ZL),$CLJS.Li,$CLJS.U($CLJS.mj,$CLJS.U(kQ,$CLJS.xj))],null),$CLJS.Gc,"Schema for a valid expression clause.",$CLJS.n(MR)?MR.H:null])):null));return $CLJS.n(a)?a:CM}(),MR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof OR?new $CLJS.Cc(function(){return OR},
$CLJS.ld(jN,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.dq,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,$CLJS.ei,$CLJS.zj,$CLJS.Bj],["0.39.0",$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kM,"metabase/mbql/schema.cljc",51,$CLJS.jF,1,382,382,$CLJS.Gc,"Schema for a `:field` clause.",$CLJS.n(OR)?OR.H:null])):null));return $CLJS.n(a)?a:kM}(),OR],null)])));
$CLJS.PR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,OO],null);$CLJS.Y(mL,gL($CLJS.ZE,$CLJS.H(["aggregation-clause-index",$CLJS.pr,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,$CLJS.xj],null)])));var QR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mL],null);
$CLJS.Y(TL,hL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof QR?new $CLJS.Cc(function(){return QR},$CLJS.ld(mla,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wQ,"metabase/mbql/schema.cljc",23,$CLJS.ZE,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ZE,Nla,$CLJS.U($CLJS.mj,$CLJS.pr),$CLJS.Li,$CLJS.U($CLJS.mj,$CLJS.U(kQ,$CLJS.xj))],null),$CLJS.Gc,"Schema for a valid aggregation clause.",$CLJS.n(QR)?QR.H:null])):null));return $CLJS.n(a)?a:wQ}(),QR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof MR?new $CLJS.Cc(function(){return MR},
$CLJS.ld(SM,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CM,"metabase/mbql/schema.cljc",60,$CLJS.qA,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.qA,$CLJS.cR,$CLJS.U($CLJS.mj,ZL),
$CLJS.Li,$CLJS.U($CLJS.mj,$CLJS.U(kQ,$CLJS.xj))],null),$CLJS.Gc,"Schema for a valid expression clause.",$CLJS.n(MR)?MR.H:null])):null));return $CLJS.n(a)?a:CM}(),MR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof OR?new $CLJS.Cc(function(){return OR},$CLJS.ld(jN,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.dq,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,
$CLJS.ei,$CLJS.zj,$CLJS.Bj],["0.39.0",$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kM,"metabase/mbql/schema.cljc",51,$CLJS.jF,1,382,382,$CLJS.Gc,"Schema for a `:field` clause.",$CLJS.n(OR)?OR.H:null])):null));return $CLJS.n(a)?a:kM}(),OR],null)])));
var RR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,TL],null),SR=new $CLJS.Rg(null,new $CLJS.h(null,11,[$CLJS.js,null,$CLJS.BF,null,$CLJS.BE,null,$CLJS.HF,null,$CLJS.IE,null,$CLJS.mF,null,$CLJS.SE,null,$CLJS.ps,null,$CLJS.zF,null,$CLJS.lF,null,$CLJS.IF,null],null),null),TR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,QL],null);
$CLJS.Y(tL,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,1,[$CLJS.vi,function(a){return"string"===typeof a?$CLJS.sj:$CLJS.n(fL(SR,a))?ML:$CLJS.n(fL($CLJS.bj,a))?$CLJS.bj:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sj,$CLJS.sj],null),new $CLJS.P(null,2,5,$CLJS.Q,[ML,TR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bj,LR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.PR],null)],null));UR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,tL],null);
VR=new $CLJS.Rg(null,new $CLJS.h(null,26,[$CLJS.EE,null,$CLJS.lI,null,$CLJS.Lq,null,$CLJS.cB,null,$CLJS.gI,null,$CLJS.XE,null,$CLJS.Zr,null,$CLJS.AE,null,$CLJS.jI,null,$CLJS.aB,null,$CLJS.mF,null,$CLJS.EF,null,$CLJS.hI,null,$CLJS.oF,null,$CLJS.GE,null,$CLJS.VH,null,$CLJS.Kq,null,$CLJS.JE,null,$CLJS.SE,null,$CLJS.PH,null,$CLJS.Pw,null,$CLJS.WH,null,$CLJS.eI,null,$CLJS.YA,null,$CLJS.GF,null,$CLJS.hF,null],null),null);
WR=new $CLJS.Rg(null,new $CLJS.h(null,9,[$CLJS.Hq,null,$CLJS.Bq,null,$CLJS.er,null,$CLJS.gr,null,$CLJS.Dq,null,$CLJS.AF,null,$CLJS.kr,null,$CLJS.Iq,null,$CLJS.Fq,null],null),null);YR=new $CLJS.Rg(null,new $CLJS.h(null,17,[$CLJS.qi,null,$CLJS.yF,null,$CLJS.sF,null,$CLJS.kF,null,$CLJS.DF,null,$CLJS.FF,null,$CLJS.OE,null,$CLJS.VE,null,$CLJS.yL,null,$CLJS.PE,null,$CLJS.wF,null,$CLJS.qF,null,$CLJS.yj,null,$CLJS.Rw,null,$CLJS.dF,null,$CLJS.eF,null,$CLJS.vF,null],null),null);
ZR=new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.bI,null,$CLJS.RH,null,$CLJS.eB,null,$CLJS.Kq,null,$CLJS.bB,null],null),null);$R=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,dQ],null);aS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,EM],null);$CLJS.bS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,FP],null);$CLJS.cS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,jQ],null);
$CLJS.Y(qQ,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"numeric expression argument",$CLJS.vi,function(a){return"number"===typeof a?$CLJS.Ps:$CLJS.n(fL(VR,a))?hO:$CLJS.n(fL(YR,a))?$CLJS.ZE:$CLJS.n(fL($CLJS.bj,a))?$CLJS.bj:$CLJS.jF}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,$CLJS.$k],null),new $CLJS.P(null,2,5,$CLJS.Q,[hO,$R],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZE,$CLJS.cS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bj,LR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jF,
$CLJS.PR],null)],null));var dS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,qQ],null);
$CLJS.Y(AP,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"datetime expression argument",$CLJS.vi,function(a){return $CLJS.n(fL(YR,a))?$CLJS.ZE:$CLJS.n(fL($CLJS.bj,a))?$CLJS.bj:$CLJS.n(fL(ZR,a))?LM:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZE,$CLJS.cS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bj,LR],null),new $CLJS.P(null,2,5,$CLJS.Q,[LM,$CLJS.bS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,JR,$CLJS.PR],null)],null)],null));
var eS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,AP],null);
$CLJS.Y(dP,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"expression argument",$CLJS.vi,function(a){return"number"===typeof a?$CLJS.Ps:$CLJS.yd(a)?$CLJS.tr:$CLJS.n(fL(WR,a))?sP:$CLJS.n(fL(VR,a))?hO:$CLJS.n(fL(ZR,a))?LM:"string"===typeof a?$CLJS.sj:$CLJS.n(fL(SR,a))?ML:$CLJS.n(fL($CLJS.bj,a))?$CLJS.bj:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,$CLJS.$k],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tr,$CLJS.tr],null),new $CLJS.P(null,2,5,$CLJS.Q,[sP,aS],null),new $CLJS.P(null,
2,5,$CLJS.Q,[hO,$R],null),new $CLJS.P(null,2,5,$CLJS.Q,[LM,$CLJS.bS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sj,$CLJS.sj],null),new $CLJS.P(null,2,5,$CLJS.Q,[ML,TR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bj,LR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.PR],null)],null));var fS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,dP],null);$CLJS.Y(bP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.er,new $CLJS.h(null,1,[$CLJS.ts,"numeric expression arg or interval"],null),Xla,dS],null));
var gS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,bP],null);$CLJS.Y(EL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"int greater than zero or numeric expression",$CLJS.vi,function(a){return"number"===typeof a?$CLJS.Ps:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,tR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$R],null)],null));var Zla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,EL],null);
$CLJS.Y(MM,gL($CLJS.mF,$CLJS.H(["a",fS,"b",fS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wi,fS],null)])));var hS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,MM],null);$CLJS.Y(qL,gL($CLJS.BE,$CLJS.H(["s",UR,"start",Zla,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,dS],null)])));var iS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,qL],null);$CLJS.Y(tP,gL($CLJS.Pw,$CLJS.H(["s",UR])));var jS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,tP],null);$CLJS.Y(SL,gL($CLJS.zF,$CLJS.H(["s",UR])));
var kS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,SL],null);$CLJS.Y(WL,gL($CLJS.IF,$CLJS.H(["s",UR])));var lS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,WL],null);$CLJS.Y(oM,gL($CLJS.BF,$CLJS.H(["s",UR])));var mS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,oM],null);$CLJS.Y($M,gL($CLJS.js,$CLJS.H(["s",UR])));var nS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$M],null);$CLJS.Y(JL,gL($CLJS.ps,$CLJS.H(["s",UR])));var oS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,JL],null);
$CLJS.Y(pQ,gL($CLJS.HF,$CLJS.H(["s",UR,"match",$CLJS.sj,"replacement",$CLJS.sj])));var qS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,pQ],null);$CLJS.Y(lR,gL($CLJS.IE,$CLJS.H(["a",UR,"b",UR,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wi,UR],null)])));var rS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,lR],null);$CLJS.Y(PN,gL($CLJS.lF,$CLJS.H(["s",UR,"pattern",$CLJS.sj])));var sS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,PN],null);
$CLJS.Y(LQ,gL($CLJS.Kq,$CLJS.H(["x",gS,"y",gS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wi,gS],null)])));var tS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,LQ],null);$CLJS.Y(JQ,gL($CLJS.Zr,$CLJS.H(["x",dS,"y",gS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wi,gS],null)])));var uS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,JQ],null);$CLJS.Y(IQ,gL($CLJS.EF,$CLJS.H(["x",dS,"y",dS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wi,dS],null)])));var vS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,IQ],null);
$CLJS.Y(KQ,gL($CLJS.Lq,$CLJS.H(["x",dS,"y",dS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wi,dS],null)])));var wS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,KQ],null);$CLJS.Y(aP,gL($CLJS.hF,$CLJS.H(["x",dS])));var xS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,aP],null);$CLJS.Y(TP,gL($CLJS.EE,$CLJS.H(["x",dS])));var yS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,TP],null);$CLJS.Y(oR,gL($CLJS.oF,$CLJS.H(["x",dS])));var zS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,oR],null);$CLJS.Y(fO,gL($CLJS.JE,$CLJS.H(["x",dS])));
var AS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,fO],null);$CLJS.Y(lO,gL($CLJS.GE,$CLJS.H(["x",dS,"y",dS])));var BS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,lO],null);$CLJS.Y(IO,gL($CLJS.XE,$CLJS.H(["x",dS])));var CS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,IO],null);$CLJS.Y(FL,gL($CLJS.AE,$CLJS.H(["x",dS])));var DS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,FL],null);$CLJS.Y(gP,gL($CLJS.GF,$CLJS.H(["x",dS])));var ES=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gP],null);
$CLJS.Y(eM,gL($CLJS.YA,$CLJS.H(["datetime-x",eS,"datetime-y",eS,"unit",Wla])));var FS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,eM],null);$CLJS.Y(wM,gL($CLJS.cB,$CLJS.H(["datetime",eS,"unit",Vla,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,ER],null)])));var GS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,wM],null);$CLJS.Y(nO,gL($CLJS.WH,$CLJS.H(["date",eS])));var HS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,nO],null);$CLJS.Y(PO,gL($CLJS.lI,$CLJS.H(["date",eS])));
var IS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,PO],null);$CLJS.Y(sL,gL($CLJS.PH,$CLJS.H(["date",eS])));var JS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,sL],null);$CLJS.Y(mR,gL($CLJS.aB,$CLJS.H(["date",eS,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,ER],null)])));var KS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mR],null);$CLJS.Y(AN,gL($CLJS.eI,$CLJS.H(["date",eS])));var LS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,AN],null);$CLJS.Y(JO,gL($CLJS.hI,$CLJS.H(["date",eS])));
var MS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,JO],null);$CLJS.Y(aM,gL($CLJS.jI,$CLJS.H(["datetime",eS])));var NS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,aM],null);$CLJS.Y(FN,gL($CLJS.gI,$CLJS.H(["datetime",eS])));var OS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,FN],null);$CLJS.Y(UL,gL($CLJS.VH,$CLJS.H(["datetime",eS])));var PS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,UL],null);$CLJS.Y(kN,gL($CLJS.RH,$CLJS.H(["datetime",eS,"to",DR,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,DR],null)])));
var QS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,kN],null),RS=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"datetime arithmetic unit"],null),$CLJS.WF,$CLJS.TF,$CLJS.Ck,$CLJS.Kk,$CLJS.Lk,$CLJS.Ri,$CLJS.Xi,$CLJS.bi,$CLJS.Wh],null);$CLJS.Y(pL,gL($CLJS.bB,$CLJS.H(["datetime",eS,"amount",dS,"unit",RS])));var SS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,pL],null);
$CLJS.Y(NL,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return gL(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.bI));var TS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,NL],null);$CLJS.Y(oL,gL($CLJS.eB,$CLJS.H(["datetime",eS,"amount",dS,"unit",RS])));var US=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,oL],null);
$CLJS.Y(FP,hL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof tS?new $CLJS.Cc(function(){return tS},$CLJS.ld(VO,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JN,"metabase/mbql/schema.cljc",51,$CLJS.Kq,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Kq,$CLJS.yD,$CLJS.U($CLJS.mj,ZO),vQ,$CLJS.U($CLJS.mj,ZO),NM,$CLJS.U($CLJS.mj,$CLJS.U($CLJS.ti,ZO))],null),$CLJS.Gc,"Schema for a valid + clause.",$CLJS.n(tS)?tS.H:null])):null));return $CLJS.n(a)?a:JN}(),tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof SS?new $CLJS.Cc(function(){return SS},
$CLJS.ld(yka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[dN,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QO,"metabase/mbql/schema.cljc",67,$CLJS.bB,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bB,$CLJS.aI,$CLJS.U($CLJS.mj,hR),PP,$CLJS.U($CLJS.mj,
UP),$CLJS.di,$CLJS.U($CLJS.mj,bQ)],null),$CLJS.Gc,"Schema for a valid datetime-add clause.",$CLJS.n(SS)?SS.H:null])):null));return $CLJS.n(a)?a:QO}(),SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof US?new $CLJS.Cc(function(){return US},$CLJS.ld(rja,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,
$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[dN,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fR,"metabase/mbql/schema.cljc",72,$CLJS.eB,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.eB,$CLJS.aI,$CLJS.U($CLJS.mj,hR),PP,$CLJS.U($CLJS.mj,UP),$CLJS.di,$CLJS.U($CLJS.mj,bQ)],null),$CLJS.Gc,"Schema for a valid datetime-subtract clause.",$CLJS.n(US)?US.H:null])):null));
return $CLJS.n(a)?a:fR}(),US],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof QS?new $CLJS.Cc(function(){return QS},$CLJS.ld(vla,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.RH,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KM,"metabase/mbql/schema.cljc",71,$CLJS.RH,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.RH,$CLJS.aI,$CLJS.U($CLJS.mj,hR),qka,$CLJS.U($CLJS.mj,yQ),vja,$CLJS.U($CLJS.mj,$CLJS.U(kQ,yQ))],null),$CLJS.Gc,"Schema for a valid convert-timezone clause.",$CLJS.n(QS)?QS.H:null])):null));return $CLJS.n(a)?a:KM}(),QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&
"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof TS?new $CLJS.Cc(function(){return TS},$CLJS.ld(Yka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.bI,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oO,"metabase/mbql/schema.cljc",
45,$CLJS.bI,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.bI],null),$CLJS.Gc,"Schema for a valid now clause.",$CLJS.n(TS)?TS.H:null])):null));return $CLJS.n(a)?a:oO}(),TS],null)])));$CLJS.VS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,LO],null);$CLJS.Y(uL,gL($CLJS.kr,$CLJS.H(["first-clause",$CLJS.VS,"second-clause",$CLJS.VS,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wi,$CLJS.VS],null)])));var WS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,uL],null);
$CLJS.Y(gN,gL($CLJS.er,$CLJS.H(["first-clause",$CLJS.VS,"second-clause",$CLJS.VS,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wi,$CLJS.VS],null)])));var XS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gN],null);$CLJS.Y(PL,gL($CLJS.gr,$CLJS.H(["clause",$CLJS.VS])));
var YS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,PL],null),ZS=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,3,[$CLJS.ts,":field or :expression reference or :relative-datetime",$CLJS.Es,$CLJS.Pe(":field or :expression reference or :relative-datetime"),$CLJS.vi,function(a){return $CLJS.n(fL($CLJS.$A,a))?$CLJS.$A:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$A,GR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.PR],null)],null);
$CLJS.Y(CP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.ts,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.er,$CLJS.tr,$CLJS.$k,$CLJS.sj,KR,ZS,fS,LR],null)],null));var $S=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,CP],null);
$CLJS.Y(UN,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"order comparable",$CLJS.vi,function(a){return $CLJS.n(fL($CLJS.bj,a))?$CLJS.bj:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bj,LR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.er,$CLJS.$k,$CLJS.sj,KR,fS,ZS],null)],null)],null));var aT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,UN],null);
$CLJS.Y(BQ,gL($CLJS.Iq,$CLJS.H(["field",$S,"value-or-field",$S,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wi,$S],null)])));var bT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,BQ],null);$CLJS.Y(FQ,gL($CLJS.AF,$CLJS.H(["field",$S,"value-or-field",$S,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wi,$S],null)])));var cT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,FQ],null);$CLJS.Y(AQ,gL($CLJS.Fq,$CLJS.H(["field",aT,"value-or-field",aT])));
var dT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,AQ],null);$CLJS.Y(zQ,gL($CLJS.Bq,$CLJS.H(["field",aT,"value-or-field",aT])));var eT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,zQ],null);$CLJS.Y(pM,gL($CLJS.Hq,$CLJS.H(["field",aT,"value-or-field",aT])));var fT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,pM],null);$CLJS.Y(vM,gL($CLJS.Dq,$CLJS.H(["field",aT,"value-or-field",aT])));var gT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,vM],null);$CLJS.Y(kP,gL($CLJS.LE,$CLJS.H(["field",aT,"min",aT,"max",aT])));
var hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,kP],null);$CLJS.Y(MP,gL($CLJS.RE,$CLJS.H(["lat-field",aT,"lon-field",aT,"lat-max",aT,"lon-min",aT,"lat-min",aT,"lon-max",aT])));var iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,MP],null);$CLJS.Y(ZM,gL($CLJS.KE,$CLJS.H(["field",$CLJS.PR])));var jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,ZM],null);$CLJS.Y(ON,gL($CLJS.WE,$CLJS.H(["field",$CLJS.PR])));var kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,ON],null);$CLJS.Y(qN,gL($CLJS.ME,$CLJS.H(["field",$CLJS.PR])));
var lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,qN],null);$CLJS.Y(xL,gL($CLJS.TE,$CLJS.H(["field",$CLJS.PR])));var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,xL],null),nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null);$CLJS.Y(DN,gL($CLJS.aF,$CLJS.H(["field",UR,"string-or-field",UR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,nT],null)])));var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,DN],null);
$CLJS.Y(wO,gL($CLJS.uF,$CLJS.H(["field",UR,"string-or-field",UR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,nT],null)])));var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,wO],null);$CLJS.Y(oN,gL($CLJS.iF,$CLJS.H(["field",UR,"string-or-field",UR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,nT],null)])));var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,oN],null);$CLJS.Y(eP,gL($CLJS.bF,$CLJS.H(["field",UR,"string-or-field",UR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,nT],null)])));
var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,eP],null);$CLJS.Y(GL,gL($CLJS.dB,$CLJS.H(["field",$CLJS.PR,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.pr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.br,$CLJS.Qu,$CLJS.Vw,$CLJS.QE],null)],null),"unit",FR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ik,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null)],null)])));var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,GL],null);
$CLJS.Y(vP,gL($CLJS.HE,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.NJ],null),qR],null)])));var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,vP],null);
$CLJS.Y(EM,hL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof WS?new $CLJS.Cc(function(){return WS},$CLJS.ld(oQ,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),YN,"metabase/mbql/schema.cljc",15,$CLJS.kr,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kr,OM,$CLJS.U($CLJS.mj,cO),GO,$CLJS.U($CLJS.mj,cO),YM,$CLJS.U($CLJS.mj,$CLJS.U($CLJS.ti,cO))],null),$CLJS.Gc,"Schema for a valid and clause.",$CLJS.n(WS)?WS.H:null])):null));return $CLJS.n(a)?a:YN}(),WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof XS?new $CLJS.Cc(function(){return XS},
$CLJS.ld(KO,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NO,"metabase/mbql/schema.cljc",14,$CLJS.er,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.er,OM,$CLJS.U($CLJS.mj,cO),GO,$CLJS.U($CLJS.mj,cO),YM,$CLJS.U($CLJS.mj,$CLJS.U($CLJS.ti,cO))],null),$CLJS.Gc,
"Schema for a valid or clause.",$CLJS.n(XS)?XS.H:null])):null));return $CLJS.n(a)?a:NO}(),XS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof YS?new $CLJS.Cc(function(){return YS},$CLJS.ld(wP,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pO,"metabase/mbql/schema.cljc",15,$CLJS.gr,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.bR,$CLJS.U($CLJS.mj,cO)],null),$CLJS.Gc,"Schema for a valid not clause.",$CLJS.n(YS)?YS.H:null])):null));return $CLJS.n(a)?a:pO}(),YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==
typeof dT?new $CLJS.Cc(function(){return dT},$CLJS.ld(TO,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RN,"metabase/mbql/schema.cljc",13,$CLJS.Fq,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fq,$CLJS.jF,$CLJS.U($CLJS.mj,QM),$P,$CLJS.U($CLJS.mj,QM)],
null),$CLJS.Gc,"Schema for a valid \x3c clause.",$CLJS.n(dT)?dT.H:null])):null));return $CLJS.n(a)?a:RN}(),dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof fT?new $CLJS.Cc(function(){return fT},$CLJS.ld(VQ,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,
1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QN,"metabase/mbql/schema.cljc",14,$CLJS.Hq,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Hq,$CLJS.jF,$CLJS.U($CLJS.mj,QM),$P,$CLJS.U($CLJS.mj,QM)],null),$CLJS.Gc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(fT)?fT.H:null])):null));return $CLJS.n(a)?a:QN}(),fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&
"undefined"!==typeof lL&&"undefined"!==typeof eT?new $CLJS.Cc(function(){return eT},$CLJS.ld(RO,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SN,"metabase/mbql/schema.cljc",13,$CLJS.Bq,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Bq,$CLJS.jF,$CLJS.U($CLJS.mj,
QM),$P,$CLJS.U($CLJS.mj,QM)],null),$CLJS.Gc,"Schema for a valid \x3e clause.",$CLJS.n(eT)?eT.H:null])):null));return $CLJS.n(a)?a:SN}(),eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof gT?new $CLJS.Cc(function(){return gT},$CLJS.ld(OQ,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],
[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZN,"metabase/mbql/schema.cljc",14,$CLJS.Dq,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dq,$CLJS.jF,$CLJS.U($CLJS.mj,QM),$P,$CLJS.U($CLJS.mj,QM)],null),$CLJS.Gc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(gT)?gT.H:null])):null));return $CLJS.n(a)?a:ZN}(),gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&
"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof bT?new $CLJS.Cc(function(){return bT},$CLJS.ld(SO,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TN,"metabase/mbql/schema.cljc",13,$CLJS.Iq,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.Iq,$CLJS.jF,$CLJS.U($CLJS.mj,$Q),$P,$CLJS.U($CLJS.mj,$Q),DP,$CLJS.U($CLJS.mj,$CLJS.U($CLJS.ti,$Q))],null),$CLJS.Gc,"Schema for a valid \x3d clause.",$CLJS.n(bT)?bT.H:null])):null));return $CLJS.n(a)?a:TN}(),bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof cT?new $CLJS.Cc(function(){return cT},$CLJS.ld(fM,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,
$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BM,"metabase/mbql/schema.cljc",14,$CLJS.AF,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.AF,$CLJS.jF,$CLJS.U($CLJS.mj,$Q),$P,$CLJS.U($CLJS.mj,$Q),DP,$CLJS.U($CLJS.mj,$CLJS.U($CLJS.ti,$Q))],null),$CLJS.Gc,"Schema for a valid !\x3d clause.",$CLJS.n(cT)?cT.H:null])):null));
return $CLJS.n(a)?a:BM}(),cT],null)])));
$CLJS.Y(LO,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"valid filter expression",$CLJS.vi,function(a){return $CLJS.n(fL(ZR,a))?$CLJS.aI:$CLJS.n(fL(VR,a))?iR:$CLJS.n(fL(SR,a))?$CLJS.sj:$CLJS.n(fL(WR,a))?$CLJS.tr:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aI,$CLJS.bS],null),new $CLJS.P(null,2,5,$CLJS.Q,[iR,$R],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sj,TR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tr,aS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,hL($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof WS?new $CLJS.Cc(function(){return WS},$CLJS.ld(oQ,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YN,"metabase/mbql/schema.cljc",
15,$CLJS.kr,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kr,OM,$CLJS.U($CLJS.mj,cO),GO,$CLJS.U($CLJS.mj,cO),YM,$CLJS.U($CLJS.mj,$CLJS.U($CLJS.ti,cO))],null),$CLJS.Gc,"Schema for a valid and clause.",$CLJS.n(WS)?WS.H:null])):null));return $CLJS.n(a)?a:YN}(),WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof XS?new $CLJS.Cc(function(){return XS},$CLJS.ld(KO,new $CLJS.h(null,1,[$CLJS.tj,
!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NO,"metabase/mbql/schema.cljc",14,$CLJS.er,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.er,OM,$CLJS.U($CLJS.mj,cO),GO,$CLJS.U($CLJS.mj,cO),YM,$CLJS.U($CLJS.mj,$CLJS.U($CLJS.ti,cO))],null),$CLJS.Gc,"Schema for a valid or clause.",$CLJS.n(XS)?
XS.H:null])):null));return $CLJS.n(a)?a:NO}(),XS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof YS?new $CLJS.Cc(function(){return YS},$CLJS.ld(wP,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),pO,"metabase/mbql/schema.cljc",15,$CLJS.gr,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.bR,$CLJS.U($CLJS.mj,cO)],null),$CLJS.Gc,"Schema for a valid not clause.",$CLJS.n(YS)?YS.H:null])):null));return $CLJS.n(a)?a:pO}(),YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof bT?new $CLJS.Cc(function(){return bT},$CLJS.ld(SO,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,
$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TN,"metabase/mbql/schema.cljc",13,$CLJS.Iq,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Iq,$CLJS.jF,$CLJS.U($CLJS.mj,$Q),$P,$CLJS.U($CLJS.mj,$Q),DP,$CLJS.U($CLJS.mj,$CLJS.U($CLJS.ti,$Q))],null),$CLJS.Gc,"Schema for a valid \x3d clause.",$CLJS.n(bT)?bT.H:null])):
null));return $CLJS.n(a)?a:TN}(),bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof cT?new $CLJS.Cc(function(){return cT},$CLJS.ld(fM,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BM,"metabase/mbql/schema.cljc",14,$CLJS.AF,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.AF,$CLJS.jF,$CLJS.U($CLJS.mj,$Q),$P,$CLJS.U($CLJS.mj,$Q),DP,$CLJS.U($CLJS.mj,$CLJS.U($CLJS.ti,$Q))],null),$CLJS.Gc,"Schema for a valid !\x3d clause.",$CLJS.n(cT)?cT.H:null])):null));return $CLJS.n(a)?a:BM}(),cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof dT?new $CLJS.Cc(function(){return dT},
$CLJS.ld(TO,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RN,"metabase/mbql/schema.cljc",13,$CLJS.Fq,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fq,$CLJS.jF,$CLJS.U($CLJS.mj,QM),$P,$CLJS.U($CLJS.mj,QM)],null),$CLJS.Gc,"Schema for a valid \x3c clause.",
$CLJS.n(dT)?dT.H:null])):null));return $CLJS.n(a)?a:RN}(),dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof eT?new $CLJS.Cc(function(){return eT},$CLJS.ld(RO,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),SN,"metabase/mbql/schema.cljc",13,$CLJS.Bq,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Bq,$CLJS.jF,$CLJS.U($CLJS.mj,QM),$P,$CLJS.U($CLJS.mj,QM)],null),$CLJS.Gc,"Schema for a valid \x3e clause.",$CLJS.n(eT)?eT.H:null])):null));return $CLJS.n(a)?a:SN}(),eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof fT?new $CLJS.Cc(function(){return fT},$CLJS.ld(VQ,new $CLJS.h(null,
1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QN,"metabase/mbql/schema.cljc",14,$CLJS.Hq,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Hq,$CLJS.jF,$CLJS.U($CLJS.mj,QM),$P,$CLJS.U($CLJS.mj,QM)],null),$CLJS.Gc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(fT)?fT.H:null])):
null));return $CLJS.n(a)?a:QN}(),fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof gT?new $CLJS.Cc(function(){return gT},$CLJS.ld(OQ,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZN,"metabase/mbql/schema.cljc",14,$CLJS.Dq,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dq,$CLJS.jF,$CLJS.U($CLJS.mj,QM),$P,$CLJS.U($CLJS.mj,QM)],null),$CLJS.Gc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(gT)?gT.H:null])):null));return $CLJS.n(a)?a:ZN}(),gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof hT?new $CLJS.Cc(function(){return hT},$CLJS.ld(Qka,new $CLJS.h(null,
1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hM,"metabase/mbql/schema.cljc",19,$CLJS.LE,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.LE,$CLJS.jF,$CLJS.U($CLJS.mj,QM),$CLJS.qi,$CLJS.U($CLJS.mj,QM),$CLJS.yj,$CLJS.U($CLJS.mj,QM)],null),$CLJS.Gc,"Schema for a valid between clause.",
$CLJS.n(hT)?hT.H:null])):null));return $CLJS.n(a)?a:hM}(),hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof oT?new $CLJS.Cc(function(){return oT},$CLJS.ld(Ska,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),eQ,"metabase/mbql/schema.cljc",23,$CLJS.aF,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.aF,$CLJS.jF,$CLJS.U($CLJS.mj,ZP),AM,$CLJS.U($CLJS.mj,ZP),$CLJS.Li,$CLJS.U($CLJS.mj,$CLJS.U(kQ,uP))],null),$CLJS.Gc,"Schema for a valid starts-with clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:eQ}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof pT?new $CLJS.Cc(function(){return pT},
$CLJS.ld(Tja,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FM,"metabase/mbql/schema.cljc",21,$CLJS.uF,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.uF,$CLJS.jF,$CLJS.U($CLJS.mj,ZP),AM,$CLJS.U($CLJS.mj,ZP),$CLJS.Li,$CLJS.U($CLJS.mj,$CLJS.U(kQ,uP))],null),
$CLJS.Gc,"Schema for a valid ends-with clause.",$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:FM}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof qT?new $CLJS.Cc(function(){return qT},$CLJS.ld(fka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,
1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RM,"metabase/mbql/schema.cljc",20,$CLJS.iF,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.iF,$CLJS.jF,$CLJS.U($CLJS.mj,ZP),AM,$CLJS.U($CLJS.mj,ZP),$CLJS.Li,$CLJS.U($CLJS.mj,$CLJS.U(kQ,uP))],null),$CLJS.Gc,"Schema for a valid contains clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:RM}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==
typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof rT?new $CLJS.Cc(function(){return rT},$CLJS.ld(Jla,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,mQ,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vL,"metabase/mbql/schema.cljc",36,$CLJS.bF,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.bF,$CLJS.jF,$CLJS.U($CLJS.mj,ZP),AM,$CLJS.U($CLJS.mj,ZP),$CLJS.Li,$CLJS.U($CLJS.mj,$CLJS.U(kQ,uP))],null),$CLJS.Gc,"Schema for a valid does-not-contain clause.",$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:vL}(),rT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof iT?new $CLJS.Cc(function(){return iT},$CLJS.ld(sla,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),
$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,mQ,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cQ,"metabase/mbql/schema.cljc",26,$CLJS.RE,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.RE,Bja,$CLJS.U($CLJS.mj,QM),Ola,$CLJS.U($CLJS.mj,QM),zla,$CLJS.U($CLJS.mj,QM),Eja,$CLJS.U($CLJS.mj,QM),tla,$CLJS.U($CLJS.mj,QM),Aja,$CLJS.U($CLJS.mj,
QM)],null),$CLJS.Gc,"Schema for a valid inside clause.",$CLJS.n(iT)?iT.H:null])):null));return $CLJS.n(a)?a:cQ}(),iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof lT?new $CLJS.Cc(function(){return lT},$CLJS.ld(gka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,mQ,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,
1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yO,"metabase/mbql/schema.cljc",28,$CLJS.ME,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ME,$CLJS.jF,$CLJS.U($CLJS.mj,JM)],null),$CLJS.Gc,"Schema for a valid is-empty clause.",$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:yO}(),lT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&
"undefined"!==typeof mT?new $CLJS.Cc(function(){return mT},$CLJS.ld(dla,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,mQ,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lP,"metabase/mbql/schema.cljc",29,$CLJS.TE,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TE,$CLJS.jF,$CLJS.U($CLJS.mj,JM)],null),
$CLJS.Gc,"Schema for a valid not-empty clause.",$CLJS.n(mT)?mT.H:null])):null));return $CLJS.n(a)?a:lP}(),mT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof jT?new $CLJS.Cc(function(){return jT},$CLJS.ld(Ela,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,mQ,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,
1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XM,"metabase/mbql/schema.cljc",27,$CLJS.KE,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KE,$CLJS.jF,$CLJS.U($CLJS.mj,JM)],null),$CLJS.Gc,"Schema for a valid is-null clause.",$CLJS.n(jT)?jT.H:null])):null));return $CLJS.n(a)?a:XM}(),jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&
"undefined"!==typeof kT?new $CLJS.Cc(function(){return kT},$CLJS.ld(ska,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,mQ,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mM,"metabase/mbql/schema.cljc",28,$CLJS.WE,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WE,$CLJS.jF,$CLJS.U($CLJS.mj,JM)],null),
$CLJS.Gc,"Schema for a valid not-null clause.",$CLJS.n(kT)?kT.H:null])):null));return $CLJS.n(a)?a:mM}(),kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof sT?new $CLJS.Cc(function(){return sT},$CLJS.ld(sja,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,mQ,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,
1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KP,"metabase/mbql/schema.cljc",33,$CLJS.dB,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.dB,$CLJS.jF,$CLJS.U($CLJS.mj,JM),$CLJS.Ej,$CLJS.U($CLJS.mj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.pr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.br,$CLJS.Qu,$CLJS.Vw,$CLJS.QE],null)],null)),$CLJS.di,$CLJS.U($CLJS.mj,XN),$CLJS.Li,$CLJS.U($CLJS.mj,$CLJS.U(kQ,Vka))],null),$CLJS.Gc,"Schema for a valid time-interval clause.",
$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:KP}(),sT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof tT?new $CLJS.Cc(function(){return tT},$CLJS.ld(Aka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,mQ,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),MQ,"metabase/mbql/schema.cljc",27,$CLJS.HE,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HE,Ija,$CLJS.U($CLJS.mj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,jla,ZL],null))],null),$CLJS.Gc,"Schema for a valid segment clause.",$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:MQ}(),tT],null)]))],null)],null));
$CLJS.Y(xP,gL($CLJS.SE,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hi,new $CLJS.h(null,1,[$CLJS.ts,":case subclause"],null),$CLJS.VS,fS],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,1,[$CLJS.ts,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.yr,!0],null),fS],null)],null)],null)])));
var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,xP],null);
$CLJS.Y(dQ,hL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof tS?new $CLJS.Cc(function(){return tS},$CLJS.ld(VO,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JN,"metabase/mbql/schema.cljc",51,$CLJS.Kq,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Kq,$CLJS.yD,$CLJS.U($CLJS.mj,ZO),vQ,$CLJS.U($CLJS.mj,ZO),NM,$CLJS.U($CLJS.mj,$CLJS.U($CLJS.ti,ZO))],null),$CLJS.Gc,"Schema for a valid + clause.",$CLJS.n(tS)?tS.H:null])):null));return $CLJS.n(a)?a:JN}(),tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof uS?new $CLJS.Cc(function(){return uS},
$CLJS.ld(Mka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KN,"metabase/mbql/schema.cljc",51,$CLJS.Zr,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Zr,$CLJS.yD,$CLJS.U($CLJS.mj,UP),
vQ,$CLJS.U($CLJS.mj,ZO),NM,$CLJS.U($CLJS.mj,$CLJS.U($CLJS.ti,ZO))],null),$CLJS.Gc,"Schema for a valid - clause.",$CLJS.n(uS)?uS.H:null])):null));return $CLJS.n(a)?a:KN}(),uS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL?new $CLJS.Cc(function(){return vS},$CLJS.ld(Lka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,mO,$CLJS.zi,$CLJS.Qi,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],
[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.bz,"metabase/mbql/schema.cljc",$CLJS.EF,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.EF,$CLJS.yD,$CLJS.U($CLJS.mj,UP),vQ,$CLJS.U($CLJS.mj,UP),NM,$CLJS.U($CLJS.mj,$CLJS.U($CLJS.ti,UP))],null),$CLJS.Gc,"Schema for a valid / clause.",$CLJS.n(vS)?vS.H:null])):null));return $CLJS.n(a)?
a:$CLJS.bz}(),vS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof wS?new $CLJS.Cc(function(){return wS},$CLJS.ld(Nka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),IN,"metabase/mbql/schema.cljc",51,$CLJS.Lq,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Lq,$CLJS.yD,$CLJS.U($CLJS.mj,UP),vQ,$CLJS.U($CLJS.mj,UP),NM,$CLJS.U($CLJS.mj,$CLJS.U($CLJS.ti,UP))],null),$CLJS.Gc,"Schema for a valid * clause.",$CLJS.n(wS)?wS.H:null])):null));return $CLJS.n(a)?a:IN}(),wS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof hS?new $CLJS.Cc(function(){return hS},
$CLJS.ld(HP,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WP,"metabase/mbql/schema.cljc",58,$CLJS.mF,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mF,EQ,$CLJS.U($CLJS.mj,AL),DQ,$CLJS.U($CLJS.mj,
AL),NM,$CLJS.U($CLJS.mj,$CLJS.U($CLJS.ti,AL))],null),$CLJS.Gc,"Schema for a valid coalesce clause.",$CLJS.n(hS)?hS.H:null])):null));return $CLJS.n(a)?a:WP}(),hS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof jS?new $CLJS.Cc(function(){return jS},$CLJS.ld(cla,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,
$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oP,"metabase/mbql/schema.cljc",56,$CLJS.Pw,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Pw,$CLJS.lx,$CLJS.U($CLJS.mj,ZP)],null),$CLJS.Gc,"Schema for a valid length clause.",$CLJS.n(jS)?jS.H:null])):null));return $CLJS.n(a)?a:oP}(),jS],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof xS?new $CLJS.Cc(function(){return xS},$CLJS.ld(Uja,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),jO,"metabase/mbql/schema.cljc",55,$CLJS.hF,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,$CLJS.yD,$CLJS.U($CLJS.mj,UP)],null),$CLJS.Gc,"Schema for a valid floor clause.",$CLJS.n(xS)?xS.H:null])):null));return $CLJS.n(a)?a:jO}(),xS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof yS?new $CLJS.Cc(function(){return yS},$CLJS.ld(Oja,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),
$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YL,"metabase/mbql/schema.cljc",54,$CLJS.EE,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EE,$CLJS.yD,$CLJS.U($CLJS.mj,UP)],null),$CLJS.Gc,"Schema for a valid ceil clause.",$CLJS.n(yS)?
yS.H:null])):null));return $CLJS.n(a)?a:YL}(),yS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof zS?new $CLJS.Cc(function(){return zS},$CLJS.ld(cka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,
1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$N,"metabase/mbql/schema.cljc",55,$CLJS.oF,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oF,$CLJS.yD,$CLJS.U($CLJS.mj,UP)],null),$CLJS.Gc,"Schema for a valid round clause.",$CLJS.n(zS)?zS.H:null])):null));return $CLJS.n(a)?a:$N}(),zS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&
"undefined"!==typeof AS?new $CLJS.Cc(function(){return AS},$CLJS.ld(Kla,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CO,"metabase/mbql/schema.cljc",53,$CLJS.JE,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.JE,$CLJS.yD,$CLJS.U($CLJS.mj,UP)],null),$CLJS.Gc,"Schema for a valid abs clause.",$CLJS.n(AS)?AS.H:null])):null));return $CLJS.n(a)?a:CO}(),AS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof BS?new $CLJS.Cc(function(){return BS},$CLJS.ld(xla,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,
iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[SQ,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nQ,"metabase/mbql/schema.cljc",69,$CLJS.GE,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.GE,$CLJS.yD,$CLJS.U($CLJS.mj,UP),vQ,$CLJS.U($CLJS.mj,UP)],null),$CLJS.Gc,"Schema for a valid power clause.",$CLJS.n(BS)?BS.H:null])):null));return $CLJS.n(a)?a:nQ}(),BS],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof CS?new $CLJS.Cc(function(){return CS},$CLJS.ld(vka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[SQ,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EN,"metabase/mbql/schema.cljc",68,$CLJS.XE,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XE,$CLJS.yD,$CLJS.U($CLJS.mj,UP)],null),$CLJS.Gc,"Schema for a valid sqrt clause.",$CLJS.n(CS)?CS.H:null])):null));return $CLJS.n(a)?a:EN}(),CS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof DS?new $CLJS.Cc(function(){return DS},$CLJS.ld(yla,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),
$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[SQ,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bO,"metabase/mbql/schema.cljc",67,$CLJS.AE,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AE,$CLJS.yD,$CLJS.U($CLJS.mj,UP)],null),$CLJS.Gc,"Schema for a valid exp clause.",$CLJS.n(DS)?
DS.H:null])):null));return $CLJS.n(a)?a:bO}(),DS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof ES?new $CLJS.Cc(function(){return ES},$CLJS.ld(Hja,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[SQ,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,
1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XQ,"metabase/mbql/schema.cljc",67,$CLJS.GF,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GF,$CLJS.yD,$CLJS.U($CLJS.mj,UP)],null),$CLJS.Gc,"Schema for a valid log clause.",$CLJS.n(ES)?ES.H:null])):null));return $CLJS.n(a)?a:XQ}(),ES],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&
"undefined"!==typeof uT?new $CLJS.Cc(function(){return uT},$CLJS.ld(LL,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tO,"metabase/mbql/schema.cljc",61,$CLJS.SE,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.SE,$CLJS.bw,$CLJS.U($CLJS.mj,cN),$CLJS.Li,$CLJS.U($CLJS.mj,$CLJS.U(kQ,MO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:tO}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof FS?new $CLJS.Cc(function(){return FS},$CLJS.ld(Kja,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,
$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YA,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DO,"metabase/mbql/schema.cljc",65,$CLJS.YA,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.YA,Xja,$CLJS.U($CLJS.mj,hR),Yja,$CLJS.U($CLJS.mj,hR),$CLJS.di,$CLJS.U($CLJS.mj,Dka)],null),$CLJS.Gc,"Schema for a valid datetime-diff clause.",
$CLJS.n(FS)?FS.H:null])):null));return $CLJS.n(a)?a:DO}(),FS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof GS?new $CLJS.Cc(function(){return GS},$CLJS.ld(gla,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.ld(SP,
new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WO,"metabase/mbql/schema.cljc",71,$CLJS.cB,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.cB,$CLJS.aI,$CLJS.U($CLJS.mj,hR),$CLJS.di,$CLJS.U($CLJS.mj,Dla),$CLJS.ii,$CLJS.U($CLJS.mj,$CLJS.U(kQ,pR))],null),$CLJS.Gc,"Schema for a valid temporal-extract clause.",$CLJS.n(GS)?GS.H:null])):null));return $CLJS.n(a)?a:WO}(),GS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof HS?new $CLJS.Cc(function(){return HS},$CLJS.ld(oka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,mQ,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),uM,"metabase/mbql/schema.cljc",71,$CLJS.WH,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WH,$CLJS.FO,$CLJS.U($CLJS.mj,hR)],null),$CLJS.Gc,"Schema for a valid get-year clause.",$CLJS.n(HS)?HS.H:null])):null));return $CLJS.n(a)?a:uM}(),HS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof IS?new $CLJS.Cc(function(){return IS},$CLJS.ld(dka,new $CLJS.h(null,1,[$CLJS.tj,!0],
null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,mQ,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UO,"metabase/mbql/schema.cljc",74,$CLJS.lI,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lI,$CLJS.FO,$CLJS.U($CLJS.mj,hR)],null),$CLJS.Gc,"Schema for a valid get-quarter clause.",
$CLJS.n(IS)?IS.H:null])):null));return $CLJS.n(a)?a:UO}(),IS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof JS?new $CLJS.Cc(function(){return JS},$CLJS.ld(Kka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,mQ,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),
$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qP,"metabase/mbql/schema.cljc",72,$CLJS.PH,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PH,$CLJS.FO,$CLJS.U($CLJS.mj,hR)],null),$CLJS.Gc,"Schema for a valid get-month clause.",$CLJS.n(JS)?JS.H:null])):null));return $CLJS.n(a)?a:qP}(),JS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==
typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof KS?new $CLJS.Cc(function(){return KS},$CLJS.ld(Cla,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,mQ,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pP,"metabase/mbql/schema.cljc",
71,$CLJS.aB,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.aB,$CLJS.FO,$CLJS.U($CLJS.mj,hR),$CLJS.ii,$CLJS.U($CLJS.mj,$CLJS.U(kQ,pR))],null),$CLJS.Gc,"Schema for a valid get-week clause.",$CLJS.n(KS)?KS.H:null])):null));return $CLJS.n(a)?a:pP}(),KS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof LS?new $CLJS.Cc(function(){return LS},$CLJS.ld(yja,new $CLJS.h(null,1,[$CLJS.tj,
!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,mQ,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iP,"metabase/mbql/schema.cljc",70,$CLJS.eI,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eI,$CLJS.FO,$CLJS.U($CLJS.mj,hR)],null),$CLJS.Gc,"Schema for a valid get-day clause.",
$CLJS.n(LS)?LS.H:null])):null));return $CLJS.n(a)?a:iP}(),LS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof MS?new $CLJS.Cc(function(){return MS},$CLJS.ld(Eka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,mQ,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),
$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$O,"metabase/mbql/schema.cljc",78,$CLJS.hI,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hI,$CLJS.FO,$CLJS.U($CLJS.mj,hR)],null),$CLJS.Gc,"Schema for a valid get-day-of-week clause.",$CLJS.n(MS)?MS.H:null])):null));return $CLJS.n(a)?a:$O}(),MS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==
typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof NS?new $CLJS.Cc(function(){return NS},$CLJS.ld(Cka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,mQ,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BL,"metabase/mbql/schema.cljc",
71,$CLJS.jI,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jI,$CLJS.aI,$CLJS.U($CLJS.mj,hR)],null),$CLJS.Gc,"Schema for a valid get-hour clause.",$CLJS.n(NS)?NS.H:null])):null));return $CLJS.n(a)?a:BL}(),NS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof OS?new $CLJS.Cc(function(){return OS},$CLJS.ld(kla,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,
$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,mQ,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aR,"metabase/mbql/schema.cljc",73,$CLJS.gI,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gI,$CLJS.aI,$CLJS.U($CLJS.mj,hR)],null),$CLJS.Gc,"Schema for a valid get-minute clause.",$CLJS.n(OS)?OS.H:null])):null));
return $CLJS.n(a)?a:aR}(),OS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof PS?new $CLJS.Cc(function(){return PS},$CLJS.ld(zka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,mQ,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,
[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vO,"metabase/mbql/schema.cljc",73,$CLJS.VH,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VH,$CLJS.aI,$CLJS.U($CLJS.mj,hR)],null),$CLJS.Gc,"Schema for a valid get-second clause.",$CLJS.n(PS)?PS.H:null])):null));return $CLJS.n(a)?a:vO}(),PS],null)])));
$CLJS.Y(QL,hL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof iS?new $CLJS.Cc(function(){return iS},$CLJS.ld(ala,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),yP,"metabase/mbql/schema.cljc",59,$CLJS.BE,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.BE,$CLJS.lx,$CLJS.U($CLJS.mj,ZP),$CLJS.vu,$CLJS.U($CLJS.mj,$ja),$CLJS.Pw,$CLJS.U($CLJS.mj,$CLJS.U(kQ,UP))],null),$CLJS.Gc,"Schema for a valid substring clause.",$CLJS.n(iS)?iS.H:null])):null));return $CLJS.n(a)?a:yP}(),iS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof kS?new $CLJS.Cc(function(){return kS},
$CLJS.ld(Ala,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",54,$CLJS.zF,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zF,$CLJS.lx,$CLJS.U($CLJS.mj,ZP)],
null),$CLJS.Gc,"Schema for a valid trim clause.",$CLJS.n(kS)?kS.H:null])):null));return $CLJS.n(a)?a:VP}(),kS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof mS?new $CLJS.Cc(function(){return mS},$CLJS.ld(Fka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,
1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EP,"metabase/mbql/schema.cljc",55,$CLJS.BF,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BF,$CLJS.lx,$CLJS.U($CLJS.mj,ZP)],null),$CLJS.Gc,"Schema for a valid ltrim clause.",$CLJS.n(mS)?mS.H:null])):null));return $CLJS.n(a)?a:EP}(),mS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&
"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof lS?new $CLJS.Cc(function(){return lS},$CLJS.ld(bla,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HO,"metabase/mbql/schema.cljc",
55,$CLJS.IF,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IF,$CLJS.lx,$CLJS.U($CLJS.mj,ZP)],null),$CLJS.Gc,"Schema for a valid rtrim clause.",$CLJS.n(lS)?lS.H:null])):null));return $CLJS.n(a)?a:HO}(),lS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof qS?new $CLJS.Cc(function(){return qS},$CLJS.ld(tja,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,
$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GQ,"metabase/mbql/schema.cljc",57,$CLJS.HF,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.HF,$CLJS.lx,$CLJS.U($CLJS.mj,ZP),Fja,$CLJS.U($CLJS.mj,$CLJS.sj),$CLJS.iz,$CLJS.U($CLJS.mj,$CLJS.sj)],null),$CLJS.Gc,"Schema for a valid replace clause.",
$CLJS.n(qS)?qS.H:null])):null));return $CLJS.n(a)?a:GQ}(),qS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof oS?new $CLJS.Cc(function(){return oS},$CLJS.ld(ela,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,
new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kR,"metabase/mbql/schema.cljc",55,$CLJS.ps,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,$CLJS.lx,$CLJS.U($CLJS.mj,ZP)],null),$CLJS.Gc,"Schema for a valid lower clause.",$CLJS.n(oS)?oS.H:null])):null));return $CLJS.n(a)?a:kR}(),oS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==
typeof lL&&"undefined"!==typeof nS?new $CLJS.Cc(function(){return nS},$CLJS.ld(Bla,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hQ,"metabase/mbql/schema.cljc",55,$CLJS.js,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,$CLJS.lx,$CLJS.U($CLJS.mj,ZP)],null),$CLJS.Gc,"Schema for a valid upper clause.",$CLJS.n(nS)?nS.H:null])):null));return $CLJS.n(a)?a:hQ}(),nS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof rS?new $CLJS.Cc(function(){return rS},$CLJS.ld(Uka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,
$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vN,"metabase/mbql/schema.cljc",56,$CLJS.IE,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.IE,EQ,$CLJS.U($CLJS.mj,ZP),DQ,$CLJS.U($CLJS.mj,ZP),NM,$CLJS.U($CLJS.mj,$CLJS.U($CLJS.ti,ZP))],null),$CLJS.Gc,"Schema for a valid concat clause.",
$CLJS.n(rS)?rS.H:null])):null));return $CLJS.n(a)?a:vN}(),rS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof sS?new $CLJS.Cc(function(){return sS},$CLJS.ld($ka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.cP,"null",$CLJS.gj,"null"],
null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sM,"metabase/mbql/schema.cljc",74,$CLJS.lF,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.lF,$CLJS.lx,$CLJS.U($CLJS.mj,ZP),$CLJS.hz,$CLJS.U($CLJS.mj,$CLJS.sj)],null),$CLJS.Gc,"Schema for a valid regex-match-first clause.",$CLJS.n(sS)?sS.H:null])):null));return $CLJS.n(a)?a:sM}(),sS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==
typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof hS?new $CLJS.Cc(function(){return hS},$CLJS.ld(HP,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cP,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
WP,"metabase/mbql/schema.cljc",58,$CLJS.mF,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mF,EQ,$CLJS.U($CLJS.mj,AL),DQ,$CLJS.U($CLJS.mj,AL),NM,$CLJS.U($CLJS.mj,$CLJS.U($CLJS.ti,AL))],null),$CLJS.Gc,"Schema for a valid coalesce clause.",$CLJS.n(hS)?hS.H:null])):null));return $CLJS.n(a)?a:WP}(),hS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof uT?new $CLJS.Cc(function(){return uT},
$CLJS.ld(LL,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tO,"metabase/mbql/schema.cljc",61,$CLJS.SE,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.SE,$CLJS.bw,$CLJS.U($CLJS.mj,cN),
$CLJS.Li,$CLJS.U($CLJS.mj,$CLJS.U(kQ,MO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:tO}(),uT],null)])));
$CLJS.vT=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,":field or :expression reference or expression",$CLJS.vi,function(a){return $CLJS.n(fL(VR,a))?iR:$CLJS.n(fL(SR,a))?$CLJS.sj:$CLJS.n(fL(WR,a))?$CLJS.tr:$CLJS.n(fL(ZR,a))?$CLJS.aI:$CLJS.n(fL($CLJS.SE,a))?$CLJS.SE:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[iR,$R],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sj,TR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tr,aS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aI,$CLJS.bS],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SE,uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.PR],null)],null);$CLJS.Y(PQ,gL($CLJS.Rw,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,$CLJS.PR],null)])));var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,PQ],null);$CLJS.Y(EO,gL($CLJS.kF,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yr,$CLJS.PR],null)])));var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,EO],null);$CLJS.Y(OL,gL($CLJS.dF,$CLJS.H(["field-or-expression",$CLJS.vT])));
var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,OL],null);$CLJS.Y(HQ,gL($CLJS.FF,$CLJS.H(["field-or-expression",$CLJS.vT])));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,HQ],null);$CLJS.Y(nN,gL($CLJS.OE,$CLJS.H(["field-or-expression",$CLJS.vT])));var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,nN],null);$CLJS.Y(XP,gL($CLJS.vF,$CLJS.H(["field-or-expression",$CLJS.vT])));var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,XP],null);$CLJS.Y(iN,gL($CLJS.qi,$CLJS.H(["field-or-expression",$CLJS.vT])));
var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,iN],null);$CLJS.Y(VM,gL($CLJS.yj,$CLJS.H(["field-or-expression",$CLJS.vT])));var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,VM],null);$CLJS.Y(PM,gL($CLJS.DF,$CLJS.H(["field-or-expression",$CLJS.vT,"pred",$CLJS.VS])));var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,PM],null);$CLJS.Y(fN,gL($CLJS.sF,$CLJS.H(["pred",$CLJS.VS])));var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,fN],null);$CLJS.Y(OP,gL($CLJS.qF,$CLJS.H(["pred",$CLJS.VS])));
var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,OP],null);$CLJS.Y(lM,gL($CLJS.yF,$CLJS.H(["field-or-expression",$CLJS.vT])));var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,lM],null);$CLJS.Y(nL,gL($CLJS.PE,$CLJS.H(["field-or-expression",$CLJS.vT])));var IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,nL],null);$CLJS.Y(rN,gL($CLJS.wF,$CLJS.H(["field-or-expression",$CLJS.vT])));var JT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,rN],null);$CLJS.Y(eO,gL($CLJS.VE,$CLJS.H(["field-or-expression",$CLJS.vT,"percentile",dS])));
var KT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,eO],null);$CLJS.Y(RP,gL($CLJS.eF,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.LJ],null),qR],null)])));var LT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,RP],null);
$CLJS.Y(NP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"unnamed aggregation clause or numeric expression",$CLJS.vi,function(a){return $CLJS.n(fL(VR,a))?hO:$CLJS.ls}],null),new $CLJS.P(null,2,5,$CLJS.Q,[hO,$R],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,hL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof yT?new $CLJS.Cc(function(){return yT},$CLJS.ld(Zka,
new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qO,"metabase/mbql/schema.cljc",60,$CLJS.dF,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dF,iQ,$CLJS.U($CLJS.mj,aQ)],null),$CLJS.Gc,"Schema for a valid avg clause.",
$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:qO}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof zT?new $CLJS.Cc(function(){return zT},$CLJS.ld(kka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.ld(SP,
new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uQ,"metabase/mbql/schema.cljc",64,$CLJS.FF,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,iQ,$CLJS.U($CLJS.mj,aQ)],null),$CLJS.Gc,"Schema for a valid cum-sum clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:uQ}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==
typeof lL&&"undefined"!==typeof AT?new $CLJS.Cc(function(){return AT},$CLJS.ld(bka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uO,"metabase/mbql/schema.cljc",65,$CLJS.OE,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OE,iQ,$CLJS.U($CLJS.mj,aQ)],null),$CLJS.Gc,"Schema for a valid distinct clause.",$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:uO}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof HT?new $CLJS.Cc(function(){return HT},$CLJS.ld(ula,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,
$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.tF,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IL,"metabase/mbql/schema.cljc",76,$CLJS.yF,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yF,iQ,$CLJS.U($CLJS.mj,aQ)],null),$CLJS.Gc,"Schema for a valid stddev clause.",$CLJS.n(HT)?HT.H:null])):null));return $CLJS.n(a)?a:IL}(),HT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof BT?new $CLJS.Cc(function(){return BT},$CLJS.ld(Pka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),dM,"metabase/mbql/schema.cljc",60,$CLJS.vF,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vF,iQ,$CLJS.U($CLJS.mj,aQ)],null),$CLJS.Gc,"Schema for a valid sum clause.",$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?a:dM}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof CT?new $CLJS.Cc(function(){return CT},$CLJS.ld(Cja,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,
$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Mr,"metabase/mbql/schema.cljc",60,$CLJS.qi,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qi,iQ,$CLJS.U($CLJS.mj,aQ)],null),$CLJS.Gc,"Schema for a valid min clause.",$CLJS.n(CT)?CT.H:
null])):null));return $CLJS.n(a)?a:$CLJS.Mr}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof DT?new $CLJS.Cc(function(){return DT},$CLJS.ld(xja,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,
1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Qr,"metabase/mbql/schema.cljc",60,$CLJS.yj,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yj,iQ,$CLJS.U($CLJS.mj,aQ)],null),$CLJS.Gc,"Schema for a valid max clause.",$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:$CLJS.Qr}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&
"undefined"!==typeof LT?new $CLJS.Cc(function(){return LT},$CLJS.ld(Zja,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uN,"metabase/mbql/schema.cljc",18,$CLJS.eF,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eF,lka,$CLJS.U($CLJS.mj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.er,pka,ZL],null))],null),$CLJS.Gc,"Schema for a valid metric clause.",$CLJS.n(LT)?LT.H:null])):null));return $CLJS.n(a)?a:uN}(),LT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof GT?new $CLJS.Cc(function(){return GT},$CLJS.ld(xka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,
$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mN,"metabase/mbql/schema.cljc",62,$CLJS.qF,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qF,$CLJS.wn,$CLJS.U($CLJS.mj,cO)],null),$CLJS.Gc,"Schema for a valid share clause.",$CLJS.n(GT)?GT.H:null])):null));return $CLJS.n(a)?a:mN}(),GT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof FT?new $CLJS.Cc(function(){return FT},$CLJS.ld(Dja,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$L,"metabase/mbql/schema.cljc",68,$CLJS.sF,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sF,$CLJS.wn,$CLJS.U($CLJS.mj,cO)],null),$CLJS.Gc,"Schema for a valid count-where clause.",$CLJS.n(FT)?FT.H:null])):null));return $CLJS.n(a)?a:$L}(),FT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof ET?new $CLJS.Cc(function(){return ET},$CLJS.ld(Ila,new $CLJS.h(null,1,[$CLJS.tj,!0],
null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rO,"metabase/mbql/schema.cljc",66,$CLJS.DF,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.DF,iQ,$CLJS.U($CLJS.mj,aQ),$CLJS.wn,$CLJS.U($CLJS.mj,cO)],null),$CLJS.Gc,"Schema for a valid sum-where clause.",
$CLJS.n(ET)?ET.H:null])):null));return $CLJS.n(a)?a:rO}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof uT?new $CLJS.Cc(function(){return uT},$CLJS.ld(LL,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.ld(SP,
new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tO,"metabase/mbql/schema.cljc",61,$CLJS.SE,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.SE,$CLJS.bw,$CLJS.U($CLJS.mj,cN),$CLJS.Li,$CLJS.U($CLJS.mj,$CLJS.U(kQ,MO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:tO}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&
"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof JT?new $CLJS.Cc(function(){return JT},$CLJS.ld(nla,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.fF,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sQ,"metabase/mbql/schema.cljc",
68,$CLJS.wF,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wF,iQ,$CLJS.U($CLJS.mj,aQ)],null),$CLJS.Gc,"Schema for a valid median clause.",$CLJS.n(JT)?JT.H:null])):null));return $CLJS.n(a)?a:sQ}(),JT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof KT?new $CLJS.Cc(function(){return KT},$CLJS.ld(Jka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,
mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.fF,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QP,"metabase/mbql/schema.cljc",72,$CLJS.VE,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.VE,iQ,$CLJS.U($CLJS.mj,aQ),$CLJS.VE,$CLJS.U($CLJS.mj,UP)],null),$CLJS.Gc,"Schema for a valid percentile clause.",$CLJS.n(KT)?KT.H:null])):
null));return $CLJS.n(a)?a:QP}(),KT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof IT?new $CLJS.Cc(function(){return IT},$CLJS.ld(Nja,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DL,"metabase/mbql/schema.cljc",77,$CLJS.PE,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PE,iQ,$CLJS.U($CLJS.mj,aQ)],null),$CLJS.Gc,"Schema for a valid var clause.",$CLJS.n(IT)?IT.H:null])):null));return $CLJS.n(a)?a:DL}(),IT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof xT?new $CLJS.Cc(function(){return xT},$CLJS.ld(zja,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,
$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,mQ,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dO,"metabase/mbql/schema.cljc",74,$CLJS.kF,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,$CLJS.jF,$CLJS.U($CLJS.mj,$CLJS.U(kQ,JM))],null),$CLJS.Gc,"Schema for a valid cum-count clause.",
$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:dO}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof wT?new $CLJS.Cc(function(){return wT},$CLJS.ld(aka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([kO,$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,mQ,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.DE,"null"],null),null),
$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.gi,"metabase/mbql/schema.cljc",70,$CLJS.Rw,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rw,$CLJS.jF,$CLJS.U($CLJS.mj,$CLJS.U(kQ,JM))],null),$CLJS.Gc,"Schema for a valid count clause.",$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:$CLJS.gi}(),wT],null)]))],null)],null));
$CLJS.Y(fP,gL($CLJS.yL,$CLJS.H(["aggregation",NP,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,1,[$CLJS.ts,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.yr,!0],null),qR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),qR],null)],null)])));
$CLJS.Y(jQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,2,[$CLJS.ts,"aggregation clause or numeric expression",$CLJS.vi,function(a){return $CLJS.n(fL($CLJS.yL,a))?$CLJS.yL:bN}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,fP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[bN,NP],null)],null));$CLJS.Y(HL,gL($CLJS.gF,$CLJS.H(["field",RR])));var MT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,HL],null);$CLJS.Y(gO,gL($CLJS.$E,$CLJS.H(["field",RR])));
NT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gO],null);
$la=hL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof MT?new $CLJS.Cc(function(){return MT},$CLJS.ld(rla,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
NN,"metabase/mbql/schema.cljc",15,$CLJS.gF,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,$CLJS.jF,$CLJS.U($CLJS.mj,CQ)],null),$CLJS.Gc,"Schema for a valid asc clause.",$CLJS.n(MT)?MT.H:null])):null));return $CLJS.n(a)?a:NN}(),MT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof NT?new $CLJS.Cc(function(){return NT},$CLJS.ld(jka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,
$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IP,"metabase/mbql/schema.cljc",16,$CLJS.$E,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$E,$CLJS.jF,$CLJS.U($CLJS.mj,CQ)],null),$CLJS.Gc,"Schema for a valid desc clause.",$CLJS.n(NT)?NT.H:null])):null));return $CLJS.n(a)?a:IP}(),NT],null)]));
OT=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.br,$CLJS.KL,$CLJS.zM,$CLJS.CE,$CLJS.Ps,$CLJS.Dj,$CLJS.FO],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,qR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lD,qR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fi,new $CLJS.h(null,1,[$CLJS.yr,!0],null),qR],null)],null);
ama=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,OT,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.KL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wL,qR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XO,tR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),tR],null)],null)],null);
bma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,OT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.zM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fQ,tR],null)],null)],null);
PT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,OT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aN,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null)],null);cma=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,tQ],null);
dma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,PT,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.CE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CE,OR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,cM],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Li,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ir,$CLJS.Di,$CLJS.ur],
null)],null)],null)],null)],null);$CLJS.QT=new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.FO,null,$CLJS.Ps,null,$CLJS.tr,null,$CLJS.Dj,null],null),null);ema=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.br],null),$CLJS.QT);
$CLJS.Y(BP,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,1,[$CLJS.vi,$CLJS.Pi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CE,dma],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KL,ama],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zM,bma],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xn,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,PT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,ema],null)],null)],null)],null)],null));
var RT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ir,qR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,BP],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.ts,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Le(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pN,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,qR],null)],null)],null),fma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,RT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FE,$CLJS.ur],null)],null)],null),ST=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,jP],null),TT=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jr,new $CLJS.h(null,1,[$CLJS.vi,function(a){return $CLJS.n($CLJS.eD($CLJS.td,
$CLJS.mP)(a))?$CLJS.mP:$CLJS.kD}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,RT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mP,$CLJS.ur],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kD,ST],null)],null),UT=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,qR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dC,rR],null),new $CLJS.P(null,2,5,$CLJS.Q,[Rka,qR],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.HN,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,sR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.xj],null)],null)],null),VT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,Sla,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.ts,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),gma=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.br],null),
new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.gE,null,$CLJS.QD,null,$CLJS.$D,null,$CLJS.dE,null],null),null)),WT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,rQ],null);
$CLJS.Y(lN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),VT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),TT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TQ,$CLJS.VS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),gma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.er,new $CLJS.h(null,1,[$CLJS.ts,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.br,$CLJS.wx,$CLJS.cz],null),WT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),qR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$J,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,vR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VN,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,UT],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.ts,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.eD($CLJS.zz($CLJS.iO,$CLJS.LP),$CLJS.Oe($CLJS.eD($CLJS.iO,$CLJS.LP)))],null)],null));var hma=$CLJS.Q,XT;var YT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,lN],null)],null);
if($CLJS.sd(YT)&&$CLJS.E.g($CLJS.z(YT),$CLJS.Zi)){var ZT=$CLJS.y(YT);$CLJS.z(ZT);var $T=$CLJS.B(ZT),ima=$CLJS.td($CLJS.z($T))?$T:$CLJS.ae(null,$T),aU=$CLJS.y(ima),jma=$CLJS.z(aU),kma=$CLJS.B(aU);XT=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.R.j(jma,$CLJS.qi,1)],null),kma)}else XT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,YT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.ts,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.Y(nM,new $CLJS.P(null,3,5,hma,[$CLJS.kr,XT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.ts,"All join aliases must be unique."],null),function(a){return $CLJS.SK($CLJS.ff($CLJS.Sa,$CLJS.cf.g($CLJS.VD,a)))}],null)],null));var lma=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,nM],null);
$CLJS.Y(rQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.h(null,1,[$CLJS.ts,"Distinct, non-empty sequence of Field clauses"],null),iL(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.qi,1],null),$CLJS.PR],null))],null));
$CLJS.Y(jP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),TT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),VT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZE,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.qi,1],null),$CLJS.cS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YE,new $CLJS.h(null,1,
[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.qi,1],null),$CLJS.PR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ir,qR,$CLJS.vT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),WT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NE,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.VS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nR,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),uR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RQ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),iL(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.qi,1],null),$la],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[nP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[nP,tR],null),new $CLJS.P(null,2,5,$CLJS.Q,[Sja,tR],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),lma],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VN,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,UT],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.ts,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.Dl(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LP,$CLJS.iO],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,
new $CLJS.h(null,1,[$CLJS.ts,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.K.g(b,$CLJS.YE);b=$CLJS.K.g(b,$CLJS.RD);return $CLJS.pd($CLJS.ct.g($CLJS.Wg(a),$CLJS.Wg(b)))}],null)],null));
$CLJS.bU=$CLJS.zg([$CLJS.CF,$CLJS.FO,hN,TM,WN,eR,CN,$CLJS.dR,$CLJS.Ps,BN,RL,YP,UQ,$CLJS.gM,QQ,eN,$CLJS.aO,zN,$CLJS.Fi,$CLJS.yN,zL,$CLJS.DM,$CLJS.xN,AO,$CLJS.tr,$CLJS.Dj,tN],[new $CLJS.h(null,1,[MN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.CF,null,$CLJS.FO,null,$CLJS.Ps,null,$CLJS.tr,null,$CLJS.Dj,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Pi,$CLJS.FO,MN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.CF,null,$CLJS.FO,null,$CLJS.dR,null,$CLJS.aO,null,$CLJS.Fi,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Pi,$CLJS.sj,$CLJS.tD,gQ,MN,new $CLJS.Rg(null,new $CLJS.h(null,1,[hN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Pi,iR,$CLJS.tD,gQ,MN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.CF,null,TM,null,$CLJS.Ps,null,$CLJS.Fi,null,zL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Pi,$CLJS.sj,$CLJS.tD,VL,MN,new $CLJS.Rg(null,new $CLJS.h(null,1,[WN,null],null),null)],null),new $CLJS.h(null,1,[MN,new $CLJS.Rg(null,new $CLJS.h(null,1,[eR,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Pi,
iR,$CLJS.tD,hla,MN,new $CLJS.Rg(null,new $CLJS.h(null,1,[CN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Pi,$CLJS.FO,MN,new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.dR,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Pi,iR,MN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.CF,null,TM,null,$CLJS.Ps,null,$CLJS.Fi,null,zL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Pi,iR,$CLJS.tD,VL,MN,new $CLJS.Rg(null,new $CLJS.h(null,1,[BN,null],null),null)],null),new $CLJS.h(null,1,[MN,new $CLJS.Rg(null,
new $CLJS.h(null,1,[RL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Pi,$CLJS.sj,$CLJS.tD,gQ,MN,new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.CF,null,eR,null,RL,null,YP,null,zN,null,$CLJS.Fi,null,zL,null,$CLJS.Dj,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Pi,$CLJS.sj,$CLJS.tD,VL,MN,new $CLJS.Rg(null,new $CLJS.h(null,1,[UQ,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Pi,$CLJS.FO,MN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.dR,null,$CLJS.gM,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Pi,$CLJS.sj,$CLJS.tD,VL,MN,new $CLJS.Rg(null,new $CLJS.h(null,1,[QQ,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Pi,$CLJS.sj,$CLJS.tD,VL,MN,new $CLJS.Rg(null,new $CLJS.h(null,1,[eN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Pi,$CLJS.FO,MN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.CF,null,$CLJS.FO,null,$CLJS.dR,null,$CLJS.aO,null,$CLJS.Fi,null],null),null)],null),new $CLJS.h(null,1,[MN,new $CLJS.Rg(null,new $CLJS.h(null,1,[zN,null],null),null)],null),new $CLJS.h(null,1,[MN,
new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.Fi,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Pi,$CLJS.FO,MN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.dR,null,$CLJS.yN,null],null),null)],null),new $CLJS.h(null,1,[MN,new $CLJS.Rg(null,new $CLJS.h(null,1,[zL,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Pi,$CLJS.FO,MN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.dR,null,$CLJS.DM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Pi,$CLJS.FO,MN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.dR,
null,$CLJS.xN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Pi,iR,$CLJS.tD,gQ,MN,new $CLJS.Rg(null,new $CLJS.h(null,1,[AO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Pi,$CLJS.tr,MN,new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.CF,null,$CLJS.Fi,null,$CLJS.tr,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Pi,$CLJS.sj,MN,new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.CF,null,eR,null,RL,null,YP,null,zN,null,$CLJS.Fi,null,zL,null,$CLJS.Dj,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Pi,
iR,$CLJS.tD,VL,MN,new $CLJS.Rg(null,new $CLJS.h(null,1,[tN,null],null),null)],null)]);$CLJS.Y(tQ,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"valid parameter type"],null)],null),$CLJS.Ng($CLJS.bU)));$CLJS.Y(cM,$CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"valid template tag widget type"],null),$CLJS.cz],null),$CLJS.Ng($CLJS.bU)));
$CLJS.Y(GP,gL(Jja,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,qR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fi,qR],null)],null)],null)])));var cU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,GP],null);$CLJS.Y(CL,gL($CLJS.CE,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.PR,cU],null)])));var dU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,CL],null);$CLJS.Y(xM,gL(wN,$CLJS.H(["target",cU])));
var eU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,xM],null),mma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.PR,hL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof dU?new $CLJS.Cc(function(){return dU},$CLJS.ld(Hka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,
1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rP,"metabase/mbql/schema.cljc",21,$CLJS.CE,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,$CLJS.oI,$CLJS.U($CLJS.mj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,JM,LN],null))],null),$CLJS.Gc,"Schema for a valid dimension clause.",$CLJS.n(dU)?dU.H:null])):null));return $CLJS.n(a)?a:rP}(),dU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=mO.h($CLJS.md("undefined"!==typeof $CLJS.jL&&
"undefined"!==typeof kL&&"undefined"!==typeof lL&&"undefined"!==typeof eU?new $CLJS.Cc(function(){return eU},$CLJS.ld(Wka,new $CLJS.h(null,1,[$CLJS.tj,!0],null)),$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,mO,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,iM,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[$CLJS.ld(SP,new $CLJS.h(null,1,[$CLJS.zj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jR,"metabase/mbql/schema.cljc",20,wN,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[wN,
$CLJS.oI,$CLJS.U($CLJS.mj,LN)],null),$CLJS.Gc,"Schema for a valid variable clause.",$CLJS.n(eU)?eU.H:null])):null));return $CLJS.n(a)?a:jR}(),eU],null)]))],null),cL=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,jM],null);
$CLJS.Y(jM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.ts,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.eD($CLJS.zz($CLJS.mP,$CLJS.FE),$CLJS.Oe($CLJS.eD($CLJS.mP,$CLJS.FE)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.ts,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.K.g(c,$CLJS.mP);var d=$CLJS.K.g(c,$CLJS.FE);c=$CLJS.K.g(c,$CLJS.Pi);c=c instanceof $CLJS.M?c.S:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.er,new $CLJS.h(null,1,[$CLJS.ts,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.dK],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.V,$CLJS.XJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.br,$CLJS.FE,$CLJS.mP],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mP,new $CLJS.h(null,1,[$CLJS.yr,!0],null),fma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FE,new $CLJS.h(null,1,[$CLJS.yr,!0],null),ST],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.P(null,
9,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,cma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fi,new $CLJS.h(null,1,[$CLJS.yr,!0],null),qR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oI,new $CLJS.h(null,1,[$CLJS.yr,!0],null),mma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.yr,!0],null),qR],null),new $CLJS.P(null,3,5,$CLJS.Q,[Pja,new $CLJS.h(null,1,[$CLJS.yr,!0],null),
qR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aN,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ur],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bK,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[Gla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),DR],null)],null)],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[Gka,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[sO,new $CLJS.h(null,1,[$CLJS.yr,!0],null),uR],null),new $CLJS.P(null,3,5,$CLJS.Q,[bM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),uR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.ts,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=
$CLJS.O(a);a=$CLJS.K.g(b,sO);b=$CLJS.K.g(b,bM);return $CLJS.Ra($CLJS.n(a)?b:a)?!0:a>=b}],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wka,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[qla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[Lja,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.tr],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[uja,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[Rja,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[ila,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wja,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.tr],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.tr],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gx,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tM,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,18,5,$CLJS.Q,[$CLJS.br,$CLJS.cj,Tka,$CLJS.pN,Vja,Qla,Xka,mka,Ika,eka,Mja,ika,fla,uka,Gja,Wja,Pla,Mla],null)],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[lla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,tR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[rka,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,tR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fQ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,Rla],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Hla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,qR],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[tka,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,tR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Fla,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ir,$CLJS.ur,$CLJS.ur],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[nka,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,tR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sN,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ir,$CLJS.ur,$CLJS.ur],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.ur],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.ts,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],
null),$CLJS.Oe($CLJS.VN)],null)],null));var fU=$CLJS.bL(cL);(function(){var a=pja();return function(b){if($CLJS.n(fU.h?fU.h(b):fU.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.ZK(b);throw $CLJS.Uh($CLJS.gD("Invalid query: {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.Cx,c,Bka,b],null));}})();