var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.util.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var xY,zoa,Aoa,Boa,Coa,EY,Doa,Eoa,FY,Foa,HY,Goa,Hoa,Ioa,MY,NY,Joa,Koa,VY,Loa,Moa,Noa,$Y,Ooa;xY=function(){};$CLJS.yY=function(a){return null!=a?$CLJS.yc===a.ni?!0:a.Wc?!1:$CLJS.Ya(xY,a):$CLJS.Ya(xY,a)};zoa=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.zY=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Aoa=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Boa=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.AY=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.BY=new $CLJS.M(null,"database-id","database-id",1883826326);Coa=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.CY=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);$CLJS.DY=new $CLJS.M(null,"search","search",1564939822);EY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Doa=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Eoa=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
FY=new $CLJS.M("metabase.lib.schema.metadata","column.has-field-values","metabase.lib.schema.metadata/column.has-field-values",-1383672167);Foa=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.GY=new $CLJS.M(null,"dataset","dataset",1159262238);HY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Goa=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);
$CLJS.IY=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.JY=new $CLJS.M(null,"definition","definition",-1198729982);Hoa=new $CLJS.M(null,"state","state",-1988618099);$CLJS.KY=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Ioa=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.LY=new $CLJS.M("source","joins","source/joins",1225821486);
MY=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);NY=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.OY=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.PY=new $CLJS.M(null,"selected?","selected?",-742502788);Joa=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);
Koa=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.QY=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.RY=new $CLJS.M("source","card","source/card",-139977973);$CLJS.SY=new $CLJS.M(null,"active","active",1895962068);$CLJS.TY=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);$CLJS.UY=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);
VY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Loa=new $CLJS.M(null,"details","details",1956795411);$CLJS.WY=new $CLJS.M("source","fields","source/fields",-649667981);$CLJS.XY=new $CLJS.M(null,"has-field-values","has-field-values",626648377);Moa=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.YY=new $CLJS.M("source","native","source/native",-1444604147);
Noa=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);$CLJS.ZY=new $CLJS.M(null,"auto-list","auto-list",314197731);$Y=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.aZ=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);Ooa=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);
$CLJS.bZ=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.cZ=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.Y(MY,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.br,$CLJS.RY,$CLJS.YY,$CLJS.AY,$CLJS.CY,$CLJS.WY,$CLJS.lK,$CLJS.cK,$CLJS.LY,$CLJS.IY,$CLJS.KY],null));$CLJS.Y(FY,$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.br],null),$CLJS.Cz($CLJS.Ed,new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.ZY,null,$CLJS.DY,null,$CLJS.aj,null,$CLJS.cz,null],null),null))));
$CLJS.Y(VY,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.kK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fi,$CLJS.UJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VJ,$CLJS.iK],null)],null));
$CLJS.Y(EY,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.eK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fi,$CLJS.UJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.ur],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.ur],null)],null)],null));
$CLJS.Y($CLJS.TJ,new $CLJS.P(null,20,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,1,[$CLJS.ts,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.KJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.sj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.zD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fi,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.iK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.dr,$CLJS.sj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ni,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.zD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$J,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.iK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.iK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bZ,
new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.qD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.qD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,MY],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aZ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.WJ],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.qD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sj,new $CLJS.h(null,2,[$CLJS.qi,1,$CLJS.yj,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.XY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,FY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,VY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fK,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,EY],null)],null)],
null)],null));$CLJS.Y($Y,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QY,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[Goa,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[Foa,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.zD],null)],null)],null)],null)],null)],null));
$CLJS.Y(HY,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SY,$CLJS.tr],null),new $CLJS.P(null,2,5,$CLJS.Q,[Hoa,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QY,$CLJS.qD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$Y],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,
$CLJS.qD],null)],null)],null));
$CLJS.Y(Moa,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.jK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fi,$CLJS.WJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BY,$CLJS.XJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.xj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UY,
new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.xj],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aK,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.PJ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Boa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.V,HY],null)],null)],null)],null));
$CLJS.Y(Joa,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.OJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fi,$CLJS.NJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aK,$CLJS.PJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.xj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.qD],null)],null)],null));
$CLJS.Y(Koa,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.QJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fi,$CLJS.LJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aK,$CLJS.PJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.xj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.qD],null)],null)],null));
$CLJS.Y(Noa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.SJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fi,$CLJS.PJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.qD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.qD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.qD],null)],null)],null));
$CLJS.Y(Aoa,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.gK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fi,$CLJS.XJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[Eoa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.xj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Loa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.xj],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.cZ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.Di],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dz,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gi,$CLJS.Di],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Ioa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bK,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.xj],null)],null)],null));
$CLJS.Y(NY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yi,new $CLJS.h(null,1,[$CLJS.ts,"Valid MetadataProvider"],null),new $CLJS.Cc(function(){return $CLJS.yY},Coa,$CLJS.zg([$CLJS.Ci,$CLJS.T,$CLJS.Cj,$CLJS.fi,$CLJS.zi,$CLJS.Qi,$CLJS.Kj,$CLJS.ei,$CLJS.zj,$CLJS.Bj],[zoa,Ooa,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Gp],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.yY)?$CLJS.yY.H:null]))],null));
$CLJS.Y(Doa,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,NY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,1,[$CLJS.ts,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,NY],null)],null)],null)],null));